import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import cornerTopLeft from '../../img/smallCornerTopLeft.svg';
import cornerTopRight from '../../img/smallCornerTopRight.svg';
import cornerBottomLeft from '../../img/smallCornerBottomLeft.svg';
import cornerBottomRight from '../../img/smallCornerBottomRight.svg';

// i moved this out then realised it complicated things
// and that there's no compelling reason to do so. so its back.
const Input = styled.input`
    font-family: Kosugi, sans-serif;
    border: none;
    padding: 2px;
    text-align: center;
    font-size: 16px;
    margin: 0;
    background: none;
    color: white;
    background-repeat: no-repeat;
    background-position: left top, right top, left bottom, right bottom;
    background-image: url(${cornerTopLeft}),
                      url(${cornerTopRight}),
                      url(${cornerBottomLeft}),
                      url(${cornerBottomRight});
    background-color: rgba(0, 0, 0, 0.7);
`;

// filtered text input
const FilteredInput = ({
  value, filter, onChange, className,
}) => {
  const [displayedValue, setDisplayedValue] = React.useState(value);
  const [focused, setFocused] = React.useState(false);

  // allow external sources to change the value if unselected
  React.useEffect(() => {
    if (!focused) setDisplayedValue(value);
  }, [value, focused]);

  const handleChange = (e) => {
    // TODO: maybe filter should be a function instead of a regex?
    const newValue = e.target.value.replace(filter, '');
    setDisplayedValue(newValue);
    // don't call back if the value is the same as the one being passed in
    if (newValue !== value) {
      e.target.value = newValue;
      onChange(e);
    }
  };

  return (
    <Input
      type="text"
      value={displayedValue}
      onChange={handleChange}
      onFocus={() => setFocused(true)}
      onBlur={() => {
        setFocused(false);
        setDisplayedValue(value);
      }}
      className={className}
    />
  );
};

FilteredInput.propTypes = {
  value: PropTypes.string,
  filter: PropTypes.instanceOf(RegExp),
  onChange: PropTypes.func,
  className: PropTypes.string,
};

FilteredInput.defaultProps = {
  value: '',
  filter: /.*/,
  onChange: null,
  className: '',
};

// input that only allows positive integers
export const NumberInput = ({
  value, min, max, onChange, className,
}) => {
  const handleChange = (e) => {
    // cap returned value to be within range
    let cappedValue = Number.isNaN(e.target.value) ? 0 : e.target.value;
    if (min) cappedValue = Math.max(min, cappedValue);
    if (max) cappedValue = Math.min(cappedValue, max);
    // don't fire if the value is the same as what we're being passed
    if (cappedValue !== Number(value)) {
      e.target.value = cappedValue;
      onChange(e);
    }
  };

  return (
    <FilteredInput
      value={value.toString()}
      filter={/\D/}
      onChange={handleChange}
      className={className}
    />
  );
};

NumberInput.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

NumberInput.defaultProps = {
  value: '',
  min: 0,
  max: 0,
  onChange: null,
  className: '',
};

// input that only allows hexadecimal values
export const HexInput = ({ value, onChange, className }) => (
  <FilteredInput
    value={value}
    filter={/[^\da-fA-F]/}
    onChange={onChange}
    className={className}
  />
);

HexInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

HexInput.defaultProps = {
  value: '',
  onChange: null,
  className: '',
};
