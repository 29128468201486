import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '../components/Dialog';
import { InlineLink } from '../components/Link';

const HelpDialog = ({ onClick }) => (
  <Dialog
    title="Welcome to Dance Zone"
    buttonText="Return"
    onClick={onClick}
    onOverlayClick={onClick}
    visible
  >
    <p>
      Enter your message into the &quot;Message Text&quot; field,
      then click &quot;Dance!&quot; to generate a preview.
    </p>
    <p>
      Adjust animation speed and text colour with &quot;Delay&quot; and
      &quot;Colour&quot; controls respectively.
    </p>
    <p>Click &quot;Generate GIF&quot; to generate a GIF using the current settings.</p>
    <p>
      <InlineLink
        href="https://www.artiestick.com/"
      >
        Dancing letter GIFs created by ARG!
      </InlineLink>
    </p>
  </Dialog>
);

HelpDialog.propTypes = {
  onClick: PropTypes.func,
};

HelpDialog.defaultProps = {
  onClick: null,
};

export default HelpDialog;
