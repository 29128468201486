const formatTime = (timeInMs) => {
  const timeInS = Math.floor(timeInMs / 1000);

  const seconds = timeInS % 60;
  const minutes = Math.floor(timeInS / 60);

  // console.log(timeInMs, minutes, seconds);
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export default formatTime;
