import styled from 'styled-components';

const Container = styled.div`
    color: white;
    padding: 5px;
`;

// container with standard borders
const BorderContainer = styled(Container)`
    border: 1px solid yellow;
    border-radius: 4px;
`;

// container with bullshit css to draw corners right regardless of browser
// note that some care is required in component design for this to work:
// 1. last child can not be position relative
// 2. last child must actually draw
// 3. any effects applied to the last child, will also affect the lower borders
// TODO: chrome still forgets what -1px looks like sometimes. workaround will be a pain tho...
const CornerContainer = styled(Container)`
    position: relative;
    padding: 15px;

    @media only screen and (max-height: 400px) {
        padding: 10px;
    }

    &::before, &::after, & > :last-child::before, & > :last-child::after {
        content: "";
        border-color: yellow;
        border-style: solid;
        opacity: 100% !important;
        position: absolute;
        width: 10px;
        height: 10px;
    }

    &::before {
        top: 0;
        left: 0;
        border-width: thin 0 0 thin;
    }

    &::after {
        top: 0;
        right: 0;
        border-width: thin thin 0 0;
    }

    & > :last-child::before {
        bottom: 0;
        left: 0;
        border-width: 0 0 thin thin;
    }

    & > :last-child::after{
        bottom: 0;
        right: 0;
        border-width: 0 thin thin 0;
    }
`;

// same as above but the corners point inwards
const CrosshairContainer = styled(CornerContainer)`
    padding: 25px;
    background-position: right bottom, left bottom, right top, left top;
`;

const ProseContainer = styled(CornerContainer)`
    text-align: left;
    line-height: 1.375;
    
    & > :first-child {
        margin-top: 0;
    }
    
    & > :last-child {
        margin-bottom: 0;
    }
`;

export {
  BorderContainer as default,
  CornerContainer,
  CrosshairContainer,
  ProseContainer,
};
