import React from 'react';
import styled from 'styled-components';

import { AsOverlay } from '../components/Overlay';
import { CornerContainer } from '../components/Container';
import { calcDrawDimensions } from './canvasUtils';

// NOTE: this is used via styled-components "as" because css does weird things
// when scaled-down images are involved...
const ImageContainer = styled(CornerContainer)`
    position: relative;
    padding: 40px;
    background-color: #000000cc;
`;

const ClickableLayer = styled.a`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const SaveMessageContainer = styled.a`
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
    background-color: #000000cc;
    text-decoration: none;
`;

const GifOverlay = AsOverlay(({ image, filename }) => {
  const [imgWidth, setImgWidth] = React.useState(undefined);
  const [imgHeight, setImgHeight] = React.useState(undefined);
  const imgEl = React.useRef(null);

  React.useEffect(() => {
    if (imgEl.current) {
      imgEl.current.onload = () => {
        const [newWidth, newHeight] = calcDrawDimensions(
          imgEl.current.width, imgEl.current.height,
          window.innerWidth * 0.7, window.innerHeight * 0.7,
        );
        setImgWidth(newWidth);
        setImgHeight(newHeight);
      };
    }
  }, []);

  return (
    <>
      <ImageContainer
        onClick={(e) => e.stopPropagation()}
      >
        <ClickableLayer
          download={filename}
          href={image}
        />
        <img
          ref={imgEl}
          src={image}
          alt={filename}
          width={imgWidth}
          height={imgHeight}
        />
      </ImageContainer>
      <CornerContainer
        as={SaveMessageContainer}
        onClick={(e) => e.stopPropagation()}
      >
        Click image to save
      </CornerContainer>
    </>
  );
});

export default GifOverlay;
