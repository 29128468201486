import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import { CornerContainer } from '../components/Container';
import { Header } from '../components/Headers';
import { FlexRouterLink } from '../components/RouterLink';

import WebDesignTopic from './WebDesignTopic';
import StreamToolsTopic from './StreamToolsTopic';
import GamesTopic from './GamesTopic';
import OtherTopic from './OtherTopic';

const AppContainerInner = styled.div`
    display: inline-flex;
    flex-direction: column;
    margin: auto;
    color: white;
    width: 100%;
    max-width: 800px;
    text-align: center;
    padding-bottom: 10px;

    & > * {
        margin-bottom: -1px;
    }

    & > :last-child {
        margin-bottom: 0;
    }

    @media only screen and (max-width: 500px) {
        width: 100%;
    }
`;

const HeaderContainer = styled(CornerContainer)`
    justify-content: center;
`;

const ButtonContainer = styled(CornerContainer)`
    display: flex;
    gap: 15px;
    justify-content: space-between;
        flex-wrap: wrap;
`;

const ButtonRow = ({ url }) => (
  <ButtonContainer>
    <FlexRouterLink
      to={`${url}/websites`}
      label="Websites"
    />
    <FlexRouterLink
      to={`${url}/streamtools`}
      label="Stream Tools"
    />
    <FlexRouterLink
      to={`${url}/games`}
      label="Games"
    />
    <FlexRouterLink
      to={`${url}/other`}
      label="Other"
    />
  </ButtonContainer>
);

ButtonRow.propTypes = {
  url: PropTypes.string.isRequired,
};

const Works = () => {
  const { path, url } = useRouteMatch();

  return (
    <AppContainerInner>
      <HeaderContainer><Header>Works</Header></HeaderContainer>
      <Switch>
        <Route path={`${path}/:id?`}>
          <ButtonRow url={url} />
        </Route>
      </Switch>
      <Switch>
        <Route exact path={path}>
          <WebDesignTopic />
        </Route>
        <Route exact path={`${path}/websites`}>
          <WebDesignTopic />
        </Route>
        <Route exact path={`${path}/streamtools`}>
          <StreamToolsTopic />
        </Route>
        <Route exact path={`${path}/games`}>
          <GamesTopic />
        </Route>
        <Route exact path={`${path}/other`}>
          <OtherTopic />
        </Route>
      </Switch>
    </AppContainerInner>
  );
};

export default Works;
