import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import formatTime from '../util';

const WinMessageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
`;

const WinMessageRow = styled.div`
    border: 1px solid yellow;
    color: white;
    padding: 10px;
    border-radius: 4px;
`;

const WinMessageText = styled.p`
    margin: 20px 0;
    text-align: center;

    &:first-child {
        margin-top: 0 !important;
    }

    &:last-child {
        margin-bottom: 0 !important;
    }
`;

const WinMessage = ({ clearTime }) => (
  <WinMessageContainer>
    <WinMessageRow>
      <WinMessageText>よくできました！</WinMessageText>
      <WinMessageText>{ `Clear Time: ${formatTime(clearTime)}` }</WinMessageText>
    </WinMessageRow>
  </WinMessageContainer>
);

WinMessage.propTypes = {
  clearTime: PropTypes.number,
};

WinMessage.defaultProps = {
  clearTime: 0,
};

export default WinMessage;
