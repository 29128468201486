import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

const CardPoolContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const CardPoolRow = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px 0;

    &:first-child {
        margin-top: 0 !important;
    }

    &:last-child {
        margin-bottom: 0 !important;
    }

    @media only screen and (max-width: 720px) {
        margin: 5px 0;
    }

    @media only screen and (max-height: 500px) {
        margin: 2px 0;
    }
`;

const CardPoolCell = styled.div`
    width: 30px;
    height: 30px;
    margin: 0 10px;

    @media only screen and (max-width: 720px) {
        margin: 0 5px;
    }

    @media only screen and (max-height: 500px) {
        margin: 0 2px;
    }
`;

const Card = styled(CardPoolCell)`
    width: 28px;
    height: 28px;
    border: 1px solid yellow;
    color: white;
    text-align: center;
    line-height: 28px;
    cursor: grab;
    transition: transform .2s;
    background-color: rgba(0, 0, 0, 0.7);
    
    &:hover {
        transform: scale(2);
        transition: transform .2s;
    }
`;

const Cell = ({ card, onDragStart }) => {
  if (!card.placed) {
    return (
      <Card
        draggable
        onDragStart={(ev) => onDragStart(ev, card.romaji)}
      >
        {card.kana}
      </Card>
    );
  }
  return <CardPoolCell />;
};

Cell.propTypes = {
  card: PropTypes.shape({
    romaji: PropTypes.string,
    kana: PropTypes.string,
    placed: PropTypes.bool,
  }),
  onDragStart: PropTypes.func,
};

Cell.defaultProps = {
  card: null,
  onDragStart: null,
};

const calcNumRows = (numCards) => {
  if (numCards <= 3) return 1;
  if (numCards <= 6) return 2;
  if (numCards <= 15) return 3;
  return 4;
};

const calcCardsPerRow = (numCards, numRows) => {
  if (numRows === 1) return [numCards];

  const rowTotals = Array(numRows).fill(Math.floor(numCards / numRows));
  switch (numRows) {
    case 2:
      // the top should take any remainders
      if (numCards % 2 === 1) rowTotals[0] += 1;
      return rowTotals;
    case 3:
      // the 1st and 3rd rows should have the same number of cards
      if (numCards % 3 === 1) rowTotals[1] += 1;
      if (numCards % 3 === 2) {
        rowTotals[0] += 1;
        rowTotals[2] += 1;
      }
      return rowTotals;
    case 4:
      // add remainders to 1st row, then 3rd, then 2nd
      if (numCards % 4 !== 0) rowTotals[0] += 1;
      if (numCards % 4 === 2) rowTotals[2] += 1;
      if (numCards % 4 === 3) {
        rowTotals[1] += 1;
        rowTotals[2] += 1;
      }
      return rowTotals;
    default:
      // TODO: this should probably just straight up be an error
      console.log(`Something went wrong - how did you end up with ${numRows} rows?`);
      return null;
  }
};

const CardPool = ({ cards, onDragStart }) => {
  const [numCardsPerRow] = React.useState(calcCardsPerRow(cards.length, calcNumRows(cards.length)));
  const [numCardsPerRowSmall] = React.useState(
    calcCardsPerRow(cards.length, Math.min(calcNumRows(cards.length), 3)),
  );

  // split the cards into rows
  // TODO: check when this runs and maybe memoise it
  const rows = [];
  let sliceStart = 0;
  numCardsPerRow.forEach((numCardsInRow) => {
    rows.push(cards.slice(sliceStart, sliceStart + numCardsInRow));
    sliceStart += numCardsInRow;
  });

  const rowsSmall = [];
  sliceStart = 0;
  numCardsPerRowSmall.forEach((numCardsInRow) => {
    rowsSmall.push(cards.slice(sliceStart, sliceStart + numCardsInRow));
    sliceStart += numCardsInRow;
  });

  const isSmall = useMediaQuery({ query: '(max-height: 500px)' });

  // then shove em in the answer table
  return (
    <CardPoolContainer>
      {
          (isSmall ? rowsSmall : rows).map((row) => (
            <CardPoolRow key={row[0].romaji}>
              {
                // cells don't actually get added or removed so using indices as keys is fine
                row.map((card, cardIdx) => (
                  <Cell
                    key={card.romaji}
                    card={card}
                    cardIdx={cardIdx}
                    onDragStart={onDragStart}
                  />
                ))
            }
            </CardPoolRow>
          ))
      }
    </CardPoolContainer>
  );
};

CardPool.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({
    romaji: PropTypes.string,
    kana: PropTypes.string,
    placed: PropTypes.bool,
  })),
  onDragStart: PropTypes.func,
};

CardPool.defaultProps = {
  cards: [],
  onDragStart: null,
};

export default CardPool;
