import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { ResizeObserver } from '@juggle/resize-observer';

import LinkList from './LinkList';
import { CornerContainer } from './components/Container';
import Overlay from './components/Overlay';

import DanceHelpDialog from './dance/HelpDialog';
import KanaHelpDialog from './kana/HelpDialog';
import SoundHelpDialog from './hdsound/HelpDialog';

// wondering if there's any way to dry this out...
const HelpMap = {
  '/kana': KanaHelpDialog,
  '/sound': SoundHelpDialog,
  '/dance': DanceHelpDialog,
};

const overlayFadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const overlayFadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const StyledOverlay = styled(Overlay)`
    position: fixed;
    z-index: 0;
    opacity: ${(props) => (props.state === 'show' ? 1 : 0)};
    animation: ${(props) => {
    if (props.state === 'fadeIn' || props.state === 'show') {
      return css`${overlayFadeIn} .5s ease`;
    }
    return css`${overlayFadeOut} .5s ease`;
  }};
`;

const MenuOverlay = ({ onClick, visible }) => {
  const [visibility, setVisibility] = React.useState('hide');

  // i cant think of a simpler way to do this than handling it like a state machine...
  React.useEffect(() => {
    if (visible && (visibility === 'hide' || visibility === 'fadeOut')) {
      setVisibility('fadeIn');
    } else if (!visible && (visibility === 'show' || visibility === 'fadeIn')) {
      setVisibility('fadeOut');
    }
  }, [visible]);

  return (
    visibility !== 'hide'
      ? (
        <StyledOverlay
          state={visibility}
          onClick={onClick}
          onAnimationEnd={() => {
            setVisibility(visible ? 'show' : 'hide');
          }}
        />
      )
      : null);
};

MenuOverlay.propTypes = {
  onClick: PropTypes.func,
  visible: PropTypes.bool,
};

MenuOverlay.defaultProps = {
  onClick: null,
  visible: true,
};

// gotta use animations for this because hiddenYOffset being set causes the transition to trigger :(
const pullOut = (props) => keyframes`
    0% {
        transform: translateY(${props.hiddenYOffset}px);
    }
    100% {
        transform: translateY(0);
    }
`;

const pullIn = (props) => keyframes`
    0% {
        transform: translateY(${-props.hiddenYOffset}px);
    }
    100% {
        transform: translateY(0);
    }
`;

const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    position: fixed;
    top: ${(props) => (props.visible ? '0' : `${Math.floor(props.hiddenYOffset)}px`)};
    left: 0;
    width: 100%;
    visibility: ${(props) => (props.hiddenYOffset === 0 ? 'hidden' : 'visible')};
`;

const MenuContainerOut = styled(MenuContainer)`
    animation: ${(props) => pullOut(props)} 0.5s;
`;

const MenuContainerIn = styled(MenuContainer)`
    animation: ${(props) => pullIn(props)} 0.5s;
`;

// TODO: this... probably shouldn't be quite so hardcoded. but whatever
const ButtonRow = styled.div`
    display: flex;
    width: 100%;
    height: 20px;
    padding: 0 19px;
    box-sizing: border-box;
    margin-top: 20px;
    background-image:
        linear-gradient(45deg, transparent 13.64px, yellow 13.64px 14.64px, transparent 14.64px),
        linear-gradient(135deg, transparent 13.64px, yellow 13.64px 14.64px, transparent 14.64px);
    background-position: bottom left, bottom right;
    background-size: 20px, 20px;
    background-repeat: no-repeat;
`;

const MenuButton = styled.button`
    display: block;
    margin: auto;
    height: 20px;
    padding: 0 5px;
    border-top: 0;
    border-left: 1px solid yellow;
    border-bottom: 0;
    border-right: 1px solid yellow;
    transform: translateY(50%);
    font-size: 1em;
    font-family: 'kosugi';
    color: yellow;
    background: none;
    cursor: pointer;
`;

const ButtonRowLine = styled.div`
    width: 100%;
    border-bottom: thin solid yellow;
`;

const SidebarHeader = styled(CornerContainer)`
    font-size: 24px;
    color: yellow;
    font-family: 'Squada One', sans-serif;
    text-align: center;
    width: calc(100% - 40px);
    padding: 10px 0;
    margin: 20px auto;
`;

const MobileMenu = ({ visible, onClick }) => {
  const [menuHeight, setMenuHeight] = React.useState(0);
  const [MenuType, setMenuType] = React.useState(MenuContainer);
  const [helpOpen, setHelpOpen] = React.useState(false);
  const menuRef = React.useRef();
  const location = useLocation();
  const HelpDialog = location.pathname in HelpMap && HelpMap[location.pathname];

  React.useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        // TODO: investigate why borderBoxSize is unavailable on some browsers
        // i suspect the polyfill is attempting to fall back to existing implementations
        // which may be outdated, and hence be missing borderBoxSize...
        if (entry.target === menuRef.current) {
          if (entry.borderBoxSize) {
            setMenuHeight(entry.borderBoxSize[0].blockSize);
          } else {
            setMenuHeight(entry.contentRect.height);
          }
        }
      });
    });

    ro.observe(menuRef.current);
  }, []);

  return (
    <>
      <MenuOverlay
        onClick={() => {
          setMenuType(visible ? MenuContainerIn : MenuContainerOut);
          onClick();
        }}
        visible={visible}
      />
      <MenuType
        visible={visible}
        hiddenYOffset={Math.min(0, 20 - menuHeight)}
        ref={menuRef}
      >
        <SidebarHeader><span>Cool Goth Zone</span></SidebarHeader>
        <LinkList
          dummy={!visible}
          onClick={() => {
            setMenuType(visible ? MenuContainerIn : MenuContainerOut);
            onClick();
          }}
        />
        <ButtonRow>
          <ButtonRowLine />
          <MenuButton
            type="button"
            onClick={() => {
              setMenuType(visible ? MenuContainerIn : MenuContainerOut);
              onClick();
            }}
          >
            MENU
          </MenuButton>
          <ButtonRowLine />
          {
              location.pathname in HelpMap && (
              <>
                <MenuButton
                  type="button"
                  onClick={() => {
                    setHelpOpen(true);
                  }}
                >
                  HELP
                </MenuButton>
                <ButtonRowLine />
              </>
              )
            }
        </ButtonRow>
      </MenuType>
      {
            helpOpen && (
            <HelpDialog
              onClick={() => {
                setHelpOpen(false);
              }}
            />
            )
        }
    </>
  );
};

MobileMenu.propTypes = {
  visible: PropTypes.bool,
  onClick: PropTypes.func,
};

MobileMenu.defaultProps = {
  visible: false,
  onClick: null,
};

export default MobileMenu;
