import React from 'react';
import styled from 'styled-components';

import FileSelector from './FileSelector';
import AudioPlayer from './AudioPlayer';
import RenderPanel from './RenderPanel';

import Encode from './encode.worker';

import { CornerContainer } from '../components/Container';
import { Header } from '../components/Headers';

const AppContainerInner = styled.div`
    display: inline-flex;
    flex-direction: column;
    margin: auto;
    color: white;
    padding-bottom: 10px;

    & > * {
        margin-bottom: -1px;
    }

    & > :last-child {
        margin-bottom: 0;
    }

    @media only screen and (max-width: 500px) {
        width: 100%;
    }
`;

const generateFileName = (originalName, ext) => {
  const base = originalName.slice(0, originalName.lastIndexOf('.'));
  return `${base}-HD_SOUND.${ext}`;
};

// 150hz 34db seems to be as close as i can get to the original
// i've tried comparing waveforms and spectrum plots and it seems like the original
// had a more gradual rolloff, which i have no control over with web audio api :(
// (beta params were 100hz +32db, which gave a less blown-out sound)
const FREQ_THRESHOLD = 150;
const GAIN = 34;

class SoundApp extends React.Component {
  constructor(props) {
    super(props);

    // these aren't tied to drawing anything so...
    // pretty sure it shouldn't be in state.
    this.targetFormat = 'mp3';
    this.targetBitrate = 128;
    this.audioContext = undefined;

    this.state = {
      file: undefined,
      fileSrc: undefined,
      srcValid: undefined,
      encodedFileURL: undefined,
      encodedFilename: undefined,
      encodingStatus: undefined,
    };
  }

  componentDidMount() {
    // set up audioContext here, we'll need it for a couple things later
    const ContextConstructor = window.AudioContext || window.webkitAudioContext;
    this.audioContext = new ContextConstructor();
  }

  // turns out if u use forwardref on a component then use that ref internally the whole
  // shit breaks if u dont pass a ref in. so we're doing it this way now.
  // tbh im not even 100% sure i need to do this here?
  // i could probably just create a second audiocontext in the audioplayer itself.
  // but this keeps it more generic and avoids making extra contexts
  setRef(ref) {
    // pass the audio element to the audio context we've been passed
    const track = this.audioContext.createMediaElementSource(ref.current);

    const lowShelfNode = this.audioContext.createBiquadFilter();
    lowShelfNode.type = 'lowshelf';
    lowShelfNode.frequency.value = FREQ_THRESHOLD;
    lowShelfNode.gain.value = GAIN;

    // chain it up
    track.connect(lowShelfNode).connect(this.audioContext.destination);
  }

  async renderAudio() {
    const { file } = this.state;
    // this is a little more modern than the old filereader approach and *should* work, i think,
    const fileData = await file.arrayBuffer();
    // decode the audio file
    const buffer = await this.audioContext.decodeAudioData(fileData);

    // gotta use an offline audio context for this
    // feels a little weird to use a fresh context for each conversion but
    // since the length is required it's kinda unavoidable
    const offlineAudioContext = new OfflineAudioContext({
      numberOfChannels: 2,
      length: 44100 * buffer.duration,
      sampleRate: 44100,
    });

    // create a buffer
    const bufferSource = offlineAudioContext.createBufferSource();
    bufferSource.buffer = buffer;

    // PUMP IT
    const lowShelfNode = offlineAudioContext.createBiquadFilter();
    lowShelfNode.type = 'lowshelf';
    lowShelfNode.frequency.value = FREQ_THRESHOLD;
    lowShelfNode.gain.value = GAIN;

    // chain it up
    bufferSource.connect(lowShelfNode).connect(offlineAudioContext.destination);
    // this line was missing from the fucking example and broke the whole thing. epic
    bufferSource.start();

    // now we render.
    const renderedBuffer = await offlineAudioContext.startRendering();
    // get data from channels so we can send it across
    const bufferData = [];
    for (let channelIdx = 0; channelIdx < renderedBuffer.numberOfChannels; channelIdx += 1) {
      bufferData.push(renderedBuffer.getChannelData(channelIdx));
    }
    // encode it so it can be unleashed into the wild
    // TODO: should i really be spawning a new worker each time or should i just set it up once??
    const encoder = new Encode();
    encoder.onmessage = (event) => {
      if (event.data.file) {
        this.setState({
          encodedFileURL: URL.createObjectURL(event.data.file),
          encodedFilename: generateFileName(file.name, this.targetFormat),
          encodingStatus: 'Encoding Complete',
        });
      } else {
        this.setState({
          encodingStatus: `Encoding (${Math.round(event.data.completion * 100)}%)`,
        });
      }
    };
    encoder.postMessage({
      bufferData,
      sampleRate: renderedBuffer.sampleRate,
      targetFormat: this.targetFormat,
      bitrate: this.targetBitrate,
    });
  }

  render() {
    const {
      fileSrc, srcValid, encodedFileURL, encodedFilename, encodingStatus,
    } = this.state;
    return (
      <>
        <AppContainerInner>
          <CornerContainer>
            <Header>Sound</Header>
          </CornerContainer>
          <FileSelector
            onChange={(e) => {
              this.setState({
                file: e.target.files[0],
                fileSrc: URL.createObjectURL(e.target.files[0]),
                encodedFileURL: undefined,
                encodedFilename: undefined,
                encodingStatus: undefined,
              });
            }}
          />
          <AudioPlayer
            src={fileSrc}
            audioContext={this.audioContext}
            onSrcValidated={(isValid) => {
              this.setState({ srcValid: isValid });
            }}
            setRef={(ref) => {
              this.setRef(ref);
            }}
          />
          <RenderPanel
            fileSrc={encodedFileURL}
            filename={encodedFilename}
            status={encodingStatus}
            onOptionsChange={(e) => {
              if (e.target.value === 'mp3 320k') {
                this.targetFormat = 'mp3';
                this.targetBitrate = 320;
              } else if (e.target.value === 'mp3 128k') {
                this.targetFormat = 'mp3';
                this.targetBitrate = 128;
              } else {
                this.targetFormat = 'wav';
                this.targetBitrate = 0; // doesnt matter,
              }
              this.setState({
                encodedFileURL: undefined,
                encodedFilename: undefined,
                encodingStatus: undefined,
              });
            }}
            onRenderClick={() => {
              this.setState({ encodingStatus: 'Reading File' });
              this.renderAudio();
            }}
            disabled={fileSrc === undefined || !srcValid}
          />
        </AppContainerInner>
      </>
    );
  }
}

export default SoundApp;
