// generic functions for doing things to/with canvases

// resize the canvas to match the size it's being displayed at
export const resizeCanvas = (canvas) => {
  const displayWidth = canvas.clientWidth;
  const displayHeight = canvas.clientHeight;

  // param reassign is unavoidable here, that's just how canvas works
  // eslint-disable-next-line no-param-reassign
  if (canvas.width !== displayWidth) canvas.width = displayWidth;
  // eslint-disable-next-line no-param-reassign
  if (canvas.height !== displayHeight) canvas.height = displayHeight;
};

// takes a width and height and scales them to fit inside maxWidth and maxHeight
// while maintaining aspect ratio
export const calcDrawDimensions = (width, height, maxWidth, maxHeight) => {
  let drawWidth = width;
  let drawHeight = height;
  // scale down if the frame is bigger than the max
  if ((width >= maxWidth) || (height >= maxHeight)) {
    const xRatio = width / maxWidth;
    const yRatio = height / maxHeight;
    if (xRatio > yRatio) {
      drawWidth = width / xRatio;
      drawHeight = height / xRatio;
    } else {
      drawWidth = width / yRatio;
      drawHeight = height / yRatio;
    }
  }
  return [drawWidth, drawHeight];
};

// replace a set of colours with another set of colour
// expects arrays of colours in rgba object format
// (i should really switch to typescript sometime lol)
export const replaceColours = (canvas, colours, targetColours) => {
  // convert to an image since we sadly can't edit pixels on a canvas
  const image = canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height);

  for (let idx = 0; idx < image.data.length; idx += 4) {
    const r = image.data[idx];
    const g = image.data[idx + 1];
    const b = image.data[idx + 2];
    const a = image.data[idx + 3];

    for (let cidx = 0; cidx < colours.length; cidx += 1) {
      const c = colours[cidx];
      const t = targetColours[cidx];
      if ((r === c.r) && (g === c.g) && (b === c.b) && (a === c.a)) {
        // swap colour if appropriate
        image.data[idx] = t.r;
        image.data[idx + 1] = t.g;
        image.data[idx + 2] = t.b;
        image.data[idx + 3] = t.a;
      }
    }
  }

  // convert back to a canvas
  // (offscreen canvas support when????????????????)
  const maskedCanvas = document.createElement('canvas');
  maskedCanvas.width = canvas.width;
  maskedCanvas.height = canvas.height;
  const maskedContext = maskedCanvas.getContext('2d');
  maskedContext.putImageData(image, 0, 0);

  return maskedCanvas;
};

// shrinks the given canvas to fit within maxWidth and maxHeight
export const smallFrame = (canvas, maxWidth, maxHeight) => {
  const { width, height } = canvas;
  const [drawWidth, drawHeight] = calcDrawDimensions(width, height, maxWidth, maxHeight);

  const newFrame = document.createElement('canvas');
  newFrame.width = drawWidth;
  newFrame.height = drawHeight;
  const newFrameContext = newFrame.getContext('2d');

  newFrameContext.drawImage(canvas, 0, 0, drawWidth, drawHeight);

  return newFrame;
};

// compile a frame to a single canvas.
// takes an array of frames and an optional array of colours
// if colours are specified, the layer will be drawn in that colour
// this is probably the least generic of the functions here,
// TODO: if this is to be truly generic each layer should really have a composite method too, right?
export const composeFrame = (frame, colours) => {
  const drawWidth = frame[0].width;
  const drawHeight = frame[0].height;

  // set up a buffer outside the loop
  const buffer = document.createElement('canvas');
  buffer.width = drawWidth;
  buffer.height = drawHeight;
  const bufferContext = buffer.getContext('2d');
  // now draw all the layers onto the frame
  const newFrame = document.createElement('canvas');
  newFrame.width = drawWidth;
  newFrame.height = drawHeight;
  const newFrameContext = newFrame.getContext('2d');
  for (let idx = 0; idx < frame.length; idx += 1) {
    const layer = frame[idx];
    if ((!!colours) && (colours[idx] !== null)) {
      const colour = colours[idx];
      // TODO: might be more optimal to swap the order here and avoid the clearRect? idk
      bufferContext.save();
      bufferContext.clearRect(0, 0, buffer.width, buffer.height);
      bufferContext.drawImage(layer, 0, 0, drawWidth, drawHeight);
      bufferContext.globalCompositeOperation = 'source-in';
      bufferContext.fillStyle = `rgb(${colour.r}, ${colour.g}, ${colour.b})`;
      bufferContext.fillRect(0, 0, buffer.width, buffer.height);
      bufferContext.restore();
      newFrameContext.drawImage(buffer, 0, 0, drawWidth, drawHeight);
    } else {
      newFrameContext.drawImage(layer, 0, 0, drawWidth, drawHeight);
    }
  }

  return newFrame;
};
