import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { ResizeObserver } from '@juggle/resize-observer';

import BackgroundSvg from './img/bg_static.svg';

// i wanted these to be spans but spans don't get to use margin-top/margin-bottom so they're divs
const BgTextLine = styled.div`
    font-size: 4vmax;
    text-transform: uppercase;
    color: #181818;
    margin-${(props) => props.offset}: 1em;
`;

// TODO: there's gotta be a better way to handle the text itself than as a prop... right?
// maybe using props.children? hmm
// TODO: scrap props.repeat and set number of horizontal and vertical repeats programatically
// the calculation is simple but needs to use the dimensions of a single line of text,
// which is less simple to obtain,
const BgText = React.forwardRef(({ text, offset, repeat }, ref) => {
  const bgTextContent = [];
  for (let idx = 0; idx < repeat; idx += 1) {
    bgTextContent.push(
      <BgTextLine ref={idx === 0 ? ref : null} offset={offset} key={idx}>{text + text}</BgTextLine>,
    );
  }

  return bgTextContent;
});

BgText.propTypes = {
  text: PropTypes.string,
  offset: PropTypes.string,
  repeat: PropTypes.number,
};

BgText.defaultProps = {
  text: '',
  offset: 'bottom',
  repeat: 2,
};

const scrollUpRight = keyframes`
    from {
        transform: translate(-75%, 75%);
    }
    to {
        transform: translate(-25%, 25%);
    }
`;

const scrollDownLeft = keyframes`
    from {
        transform: translate(-25%, 25%);
    }
    to {
        transform: translate(-75%, 75%);
    }
`;

// TODO: parameterise animation length? speed is currently tied to the size of the contained div...
// TODO: maybe disable will-change when not animating?
const Scroller = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    ${(props) => (props.height > 0 ? `width: ${props.height}px` : '')};
    height: ${(props) => `${props.height}px`};
    animation: ${(props) => (props.direction === 'right' ? scrollUpRight : scrollDownLeft)} 90s linear infinite;
    animation-play-state: ${(props) => (props.paused ? 'paused' : 'running')};
    will-change: transform;
`;

const Rotator = styled.div`
    display: inline-block;
    transform: rotate(-45deg);
    transform-origin: 0 0;
`;

const BackgroundContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: -999;
    width: 100%;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
`;

const scrollMessageLeft = 'This place is not a place of honor. '
    + 'No highly esteemed deed is commemorated here. Nothing valued is here.\u00A0';
const scrollMessageRight = 'What is here was dangerous and repulsive to us. '
    + 'This message is a warning about danger.\u00A0';

const calcSquareSize = (hyp) => Math.sqrt(hyp ** 2 / 2);

const Background = ({ paused }) => {
  const [scrollerSizeLeft, setScrollerSizeLeft] = React.useState(0);
  const [scrollerSizeRight, setScrollerSizeRight] = React.useState(0);
  const bgTextRefLeft = React.useRef();
  const bgTextRefRight = React.useRef();

  React.useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === bgTextRefLeft.current) {
          setScrollerSizeLeft(calcSquareSize(entry.contentRect.width));
        } else if (entry.target === bgTextRefRight.current) {
          setScrollerSizeRight(calcSquareSize(entry.contentRect.width));
        }
      });
    });

    ro.observe(bgTextRefLeft.current);
    ro.observe(bgTextRefRight.current);
  }, []);

  return (
    <BackgroundContainer>
      <Scroller height={scrollerSizeLeft} direction="left" paused={paused}>
        <Rotator>
          <BgText
            ref={bgTextRefLeft}
            text={scrollMessageLeft}
            offset="bottom"
            repeat={17}
          />
        </Rotator>
      </Scroller>
      <Scroller height={scrollerSizeRight} direction="right" paused={paused}>
        <Rotator>
          <BgText
            ref={bgTextRefRight}
            text={scrollMessageRight}
            offset="top"
            repeat={17}
          />
        </Rotator>
      </Scroller>
    </BackgroundContainer>
  );
};

Background.propTypes = {
  paused: PropTypes.bool,
};

Background.defaultProps = {
  paused: true,
};

const MobileBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: -999;
    width: 100%;
    height: 100%;
    background-image: url("${BackgroundSvg}");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
`;

export {
  Background as default,
  MobileBackground,
};
