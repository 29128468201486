import styled from 'styled-components';

// TODO: rename these to just h1, h2 etc for fuck sake
// maybe Header should be like PageTitle or somethin given its not to be used in prose...

const Header = styled.h1`
    text-align: center;
    margin: 0;
    color: #ff0;
    font-size: 7em;
    font-family: 'Squada One', sans-serif;
    text-transform: uppercase;

    @media only screen and (max-width: 850px) {
        font-size: 5em;
    }

    @media only screen and (max-width: 650px) {
        font-size: 3.5em;
    }
`;

const Subheader = styled.h2`
    text-align: center;
    margin: 0;
    font-size: 1.625em;
    line-height: 1.5em;
    color: white;

    @media only screen and (max-width: 850px) {
        font-size: 1.4em;
    }
`;

const Subsubheader = styled.h3`
    text-align: center;
    margin: 0;
    margin-bottom: 10px;
    font-size: 1.325em;
    line-height: 1.2em;
    color: white;

    @media only screen and (max-width: 850px) {
        font-size: 1.2em;
    }
`;

export { Header, Subheader, Subsubheader };
