import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../components/Button';

const ModeSelectorContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 180px;

    @media only screen and (max-height: 500px) {
        height: 98px;
    }
`;

const ModeSelectorRow = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

// TODO: rename to options screen?
const ModeSelector = ({ onClick }) => (
  <ModeSelectorContainer>
    <ModeSelectorRow>
      <Button onClick={() => onClick('HIRAGANA')}>Hiragana</Button>
      <Button onClick={() => onClick('KATAKANA')}>Katakana</Button>
    </ModeSelectorRow>
  </ModeSelectorContainer>
);

ModeSelector.propTypes = {
  onClick: PropTypes.func,
};

ModeSelector.defaultProps = {
  onClick: null,
};

export default ModeSelector;
