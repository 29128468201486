import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from './Button';
import { ProseContainer } from './Container';
import { AsOverlay } from './Overlay';

const HelpDialogContainer = styled(ProseContainer)`
    max-width: 500px;
    padding: 30px;
    margin: 10px;
    background-color: #000000cc;
    text-align: center;

    p:first-of-type{
        margin-top: 0;
    }

    p:last-of-type {
        margin-bottom: 0;
    }
`;

const HelpDialogHeader = styled.h1`
    margin-top: 0;
    margin-bottom: 24px;
    color: yellow;
    font-family: 'Squada One', sans-serif;
    text-transform: uppercase;
    line-height: 1;
`;

const SpacedButton = styled(Button)`
    margin-top: 30px;
`;

// TODO: set line height for children here
const Dialog = AsOverlay(({
  title, buttonText, onClick, children,
}) => (
  <HelpDialogContainer
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    <HelpDialogHeader>{ title }</HelpDialogHeader>
    { children }
    <SpacedButton
      onClick={(e) => {
        e.stopPropagation(); onClick();
      }}
    >
      { buttonText }
    </SpacedButton>
  </HelpDialogContainer>
));

Dialog.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

Dialog.defaultProps = {
  title: '',
  buttonText: '',
  onClick: null,
  children: null,
};

export default Dialog;
