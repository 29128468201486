import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { MenuRouterLink } from './components/RouterLink';

// splitting this out for brain reasons. easier to reason about styling if i'm not inheriting
// if the styles end up similar i can always shove it back into the same file

const SublinkContainer = styled.li`
    width: 100%;
    height: ${(props) => (props.expanded === 'visible' ? (props.numItems + 1) * 60 - 20 : 40)}px;
    transition: height .25s;
    ${(props) => props.expanded === 'retracting' && 'transition-delay: .25s;'}
`;

const SublinkParentItem = styled.span`
    display: flex;
    align-items: center;

    &::before, &::after {
        content: "";
        display: inline-block;
        width: 20px;
        border-top: thin solid yellow;
    }
`;

// TODO: clean up this state machine shit lol
const StyledSublinkList = styled.ul`
    list-style-type: none;
    width: 100%;
    padding: 0;
    margin: 20px 0 0;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    visibility: ${(props) => (props.expanded !== 'hidden' ? 'visible' : 'hidden')};
    transform: translateX(${(props) => (props.expanded === 'visible' ? '0' : '-100%')});
    transition: transform .25s;
    ${(props) => props.expanded === 'visible' && 'transition-delay: .25s'};
`;

const SublinkListItem = styled.li`
    display: flex;
    align-items: center;
    position: relative;

    &::before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 60px;
        position: absolute;
        top: -40px;
        left: 9px;
        border-left: thin solid yellow;
        border-bottom: thin solid yellow;
    }

    & > * {
        margin-left: 20px;
    }
    
    &::after {
        content: "";
        display: inline-block;
        width: 20px;
        border-top: thin solid yellow;
    }
`;

// TODO: i cant believe i'm casting a link that uses button styling, back into a fucking button
// surely there's a better way...?
// TODO: i think i can figure out a way to keep the submenus open when retracting the
// parent menu but it's gonna need a couple extra props...
const SublinkList = ({
  label, sublinks, dummy, onClick,
}) => {
  const [expanded, setExpanded] = useState('hidden');

  return (
    <SublinkContainer
      expanded={expanded}
      numItems={sublinks.length}
      onTransitionEnd={() => expanded === 'retracting' && setExpanded('hidden')}
    >
      <SublinkParentItem>
        <MenuRouterLink
          label={label}
          as="button"
          onClick={() => {
            setExpanded(expanded !== 'hidden' ? 'retracting' : 'visible');
          }}
        />
      </SublinkParentItem>
      <StyledSublinkList
        expanded={expanded}
        onTransitionEnd={() => expanded === 'retracting' && setExpanded('hidden')}
      >
        {
          sublinks.map((sublink) => (
            <SublinkListItem key={sublink.label}>
              <MenuRouterLink
                to={sublink.to}
                label={sublink.label}
                exact={sublink.exact}
                target={sublink.target}
                dummy={dummy}
                onClick={onClick}
              />
            </SublinkListItem>
          ))
        }
      </StyledSublinkList>
    </SublinkContainer>
  );
};

SublinkList.propTypes = {
  label: PropTypes.string.isRequired,
  dummy: PropTypes.bool,
  onClick: PropTypes.func,
  sublinks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

SublinkList.defaultProps = {
  dummy: false,
  onClick: null,
};

export default SublinkList;
