import React from 'react';
import styled from 'styled-components';
import { Link, useRouteMatch } from 'react-router-dom';

import { buttonStyle } from './Button';

// react-router "navlinks". they're intended to appear in groups
// TODO: i'd never thought about it before but. should all of these be 30px height??

// menu link. links for the sidebar menu. these are react-router links (mostly)
const StyledMenuLink = styled(Link)`
    ${buttonStyle}
    display: inline-block;
    flex-grow: 1;
    height: 40px;
    line-height: 38px;
    margin: 0;
    font-size: 18px;
    text-transform: uppercase;
  
    ${(props) => props.active && 'color: black; background: yellow;'}

    &:hover, &:active {
        color: black;
        background: yellow;
    }
`;

// links intended for use in a row flex w/ wrapping. they'll sit 4 to a line, then
// come down to 2, then 1 per line as the page shrinks
// setting flex basis to 40% is just to make sure only 2 items can fit on one line at that width
// i could do this with widths instead but. it's messy.
const StyledFlexLink = styled(StyledMenuLink)`
    flex-grow: 1;
    flex-basis: 0;

    @media only screen and (max-width: 650px) {
        flex-basis: 40%;
    }

    @media only screen and (max-width: 500px) {
        flex-basis: 100%;
    }
`;

// turns a link into a cut-price navlink.
// i gotta do this cos actual NavLink from react-router has some issues
// (ie. the component prop on it is fucking useless)
const asNavLink = (WrappedComponent) => ({
  label, to, exact, ...other
}) => {
  const match = useRouteMatch({
    path: to instanceof Object ? to.pathname : to,
    exact,
  });

  return (
    <WrappedComponent
      exact={exact}
      to={to}
      active={match}
      // shut the hell up bitch
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {label}
    </WrappedComponent>
  );
};

// adds an extra "dummy" prop, which swaps the link for a span with the same styling
// use this on links that go off-screen to prevent em being selected via keyboard while hidden
const asDummyable = (WrappedComponent) => ({ dummy, ...other }) => (
  <WrappedComponent
    as={dummy && 'span'}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...other}
  />
);

const MenuRouterLink = asDummyable(asNavLink(StyledMenuLink));
const FlexRouterLink = asNavLink(StyledFlexLink);

export {
  MenuRouterLink,
  FlexRouterLink,
};
