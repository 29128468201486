import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
    position: fixed;
    left: 0px;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: #000000cc;
    margin: 0;
    z-index: 999;
`;

// turns the wrapped component into an overlay
const AsOverlay = (Wrapped) => ({ visible, onOverlayClick, ...other }) => (visible ? (
  <Overlay onClick={onOverlayClick}>
    <Wrapped
      // eslint-disable-next-line
      {...other}
    />
  </Overlay>
) : null);

export {
  Overlay as default,
  AsOverlay,
};
