import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CornerContainer } from '../components/Container';
import Button from '../components/Button';

const RadioButton = styled.input.attrs({
  type: 'radio',
  name: 'encoding-options',
})`
    position: fixed;
    opacity: 0;
    pointer-events: none;
`;

const FakeRadioButton = styled(Button).attrs({
  as: 'label',
})`
    width: 100%;

    ${RadioButton}:enabled:checked + & {
        color: black;
        background: yellow;
    }

    ${RadioButton}:enabled + &:hover, ${RadioButton}:enabled + &:active {
        color: black;
        background: yellow;
    }

    ${RadioButton}:disabled + & {
        cursor: not-allowed;
        color: rgba(255, 255, 255, .5);
        border-color: rgba(255, 255, 0, .5);
        background-color: rgba(0, 0, 0, .35);
    }
`;

const OptionsContainer = styled.div`
    display: flex;

    & > * {
        margin-right: 10px;
    }

    & > :last-child {
        margin-right: 0;
    }
`;

const RenderButton = styled(Button)`
    width: 100%;
`;

const DownloadButton = styled(Button).attrs({
  as: 'a',
})`
    width: 100%;

    ${(props) => (props.href !== null ? `
    &:hover, &:active {
        color: black;
        background: yellow;
    }
    ` : `
    cursor: not-allowed;
    color: rgba(255, 255, 255, .5);
    border-color: rgba(255, 255, 0, .5);
    background-color: rgba(0, 0, 0, .35);
    `)}
`;

const RenderPanelContainer = styled(CornerContainer)`
    display: flex;
    flex-direction: column;

    & > * {
        margin-bottom: 10px;
    }

    & > :last-child {
        margin-bottom: 0;
    }
`;

const Options = ({ onChange, disabled }) => (
  <OptionsContainer>
    <RadioButton
      value="mp3 128k"
      id="mp3-128k"
      onChange={onChange}
      defaultChecked
      disabled={disabled}
    />
    <FakeRadioButton
      htmlFor="mp3-128k"
    >
      MP3&nbsp;(128K)
    </FakeRadioButton>
    <RadioButton
      value="mp3 320k"
      id="mp3-320k"
      onChange={onChange}
      disabled={disabled}
    />
    <FakeRadioButton
      htmlFor="mp3-320k"
    >
      MP3&nbsp;(320K)
    </FakeRadioButton>
    <RadioButton
      value="wav"
      id="wav"
      onChange={onChange}
      disabled={disabled}
    />
    <FakeRadioButton
      htmlFor="wav"
    >
      WAV
    </FakeRadioButton>
  </OptionsContainer>
);

Options.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

Options.defaultProps = {
  onChange: () => {},
  disabled: false,
};

const RenderPanel = ({
  fileSrc, filename, status, onOptionsChange, onRenderClick, disabled,
}) => (
  <RenderPanelContainer>
    <Options
      onChange={onOptionsChange}
      disabled={disabled}
    />
    <RenderButton
      type="button"
      onClick={onRenderClick}
      disabled={disabled || status}
    >
      {status || 'Encode'}
    </RenderButton>
    <DownloadButton
      as="a"
      href={fileSrc}
      download={filename}
    >
      Download
    </DownloadButton>
  </RenderPanelContainer>
);

RenderPanel.propTypes = {
  fileSrc: PropTypes.string,
  filename: PropTypes.string,
  status: PropTypes.string,
  onOptionsChange: PropTypes.func,
  onRenderClick: PropTypes.func,
  disabled: PropTypes.bool,
};

RenderPanel.defaultProps = {
  fileSrc: null,
  filename: null,
  status: undefined,
  onOptionsChange: () => {},
  onRenderClick: () => {},
  disabled: false,
};

export default RenderPanel;
