import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// styling range sliders is horrible so here's a semi-parameterised template
const THUMB_BG = 'black';
const THUMB_BORDER = '1px solid yellow';

const TRACK_BG = 'yellow';
const TRACK_BORDER = 'none';

const SliderInput = styled.input.attrs({
  type: 'range',
})`
    background: none;
    -webkit-appearance: none;
    width: 100%;
    margin: 0 0;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 2px;
      cursor: pointer;
      background: ${TRACK_BG};
      border-radius: 0px;
      border: ${TRACK_BORDER};
    }

    &::-webkit-slider-thumb {
      border: ${THUMB_BORDER};
      height: 10px;
      width: 4px;
      border-radius: 0px;
      background: ${THUMB_BG};
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -5px;
    }
    
    &:focus::-webkit-slider-runnable-track {
      background: #ffff1f;
    }

    &::-moz-range-track {
      width: 100%;
      height: 2px;
      cursor: pointer;
      background: ${TRACK_BG};
      border-radius: 0px;
      border: ${TRACK_BORDER};
    }

    &::-moz-range-thumb {
      border: ${THUMB_BORDER};
      height: 10px;
      width: 4px;
      border-radius: 0px;
      background: ${THUMB_BG};
      cursor: pointer;
    }

    &::-ms-track {
      width: 100%;
      height: 2px;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    &::-ms-fill-lower {
      background: #e0e000;
      border: ${TRACK_BORDER};
      border-radius: 0px;
    }

    &::-ms-fill-upper {
      background: ${TRACK_BG};
      border: ${TRACK_BORDER};
      border-radius: 0px;
    }

    &::-ms-thumb {
      border: ${THUMB_BORDER};
      height: 10px;
      width: 4px;
      border-radius: 0px;
      background: ${THUMB_BG};
      cursor: pointer;
      height: 2px;
    }

    &:focus::-ms-fill-lower {
      background: ${TRACK_BG};
    }

    &:focus::-ms-fill-upper {
      background: #ffff1f;
    }
`;

const SLIDER_MIN = 0;
const SLIDER_MAX = 100;

const toSliderVal = (min, max, val) => {
  const logMin = Math.log(min);
  const logMax = Math.log(max);
  const scale = (logMax - logMin) / (SLIDER_MAX - SLIDER_MIN);
  return (Math.log(val) - logMin) / scale + SLIDER_MIN;
};

const toTrueVal = (min, max, val) => {
  const logMin = Math.log(min);
  const logMax = Math.log(max);
  const scale = (logMax - logMin) / (SLIDER_MAX - SLIDER_MIN);
  return Math.exp(logMin + scale * (val - SLIDER_MIN));
};

// this slider is exponential - the higher the value, the faster it changes
// i can't imagine i'll use it anywhere but the dance app delay control but still.
// gotta have it.
const ExponentialSlider = ({
  value, min, max, onChange, onMouseDown, onMouseUp, className,
}) => (
  <SliderInput
    value={toSliderVal(min, max, value)}
    min={SLIDER_MIN}
    max={SLIDER_MAX}
    onChange={(e) => {
      onChange(Math.round(toTrueVal(min, max, e.target.value)));
    }}
    onMouseDown={onMouseDown}
    onMouseUp={onMouseUp}
    className={className}
  />
);

ExponentialSlider.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  className: PropTypes.string,
};

ExponentialSlider.defaultProps = {
  value: 0,
  min: 0,
  max: 0,
  onChange: () => {},
  onMouseDown: () => {},
  onMouseUp: () => {},
  className: '',
};

// "hey freya, why bother defining this instead of just returning SliderInput as Slider?"
// the answer is simple: for consistency with ExponentialSlider.
// (especially since ExponentialSlider actually came first,)
const Slider = ({
  value, min, max, onChange, onMouseDown, onMouseUp, className,
}) => (
  <SliderInput
    value={value}
    min={min}
    max={max}
    onChange={(e) => {
      onChange(Math.round(e.target.value));
    }}
    onMouseDown={onMouseDown}
    onMouseUp={onMouseUp}
    className={className}
  />
);

Slider.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  className: PropTypes.string,
};

Slider.defaultProps = {
  value: 0,
  min: 0,
  max: 0,
  onChange: () => {},
  onMouseDown: () => {},
  onMouseUp: () => {},
  className: '',
};

export {
  Slider as default,
  ExponentialSlider,
};
