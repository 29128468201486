import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ColourPicker from './controls/ColourPicker';
import DelayControl from './controls/DelayControl';
import MessageControl from './controls/MessageControl';
import { CornerContainer } from '../components/Container';

const ControlPanelContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: auto 0 -1px 0;
    text-align: center;

    & > * {
        margin-right: -1px;
    }

    & > :last-child {
        margin-right: 0;
    }

    @media only screen and (max-width: 800px) {
        & > :first-child {
            flex-basis: 100% !important;
            margin-right: 0;
            margin-bottom: -1px;
        }
    }

    @media only screen and (max-width: 600px) {
        & > * {
            flex-basis: 100% !important;
            margin-right: 0;
            margin-bottom: -1px;
        }

        & > :last-child {
            margin-bottom: 0;
        }
    }
`;

const ControlPanelColumn = styled(CornerContainer)`
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 33.3%;

    & > div > div {
        height: 100px;
    }
`;

const ColumnTitle = styled.span`
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
`;

const AsColumn = (Wrapped) => ({ title, ...props }) => (
  <ControlPanelColumn>
    <div>
      <ColumnTitle>{title}</ColumnTitle>
      <Wrapped
      // eslint-disable-next-line
      {...props}
      />
    </div>
  </ControlPanelColumn>
);

const MessageColumn = AsColumn(MessageControl);
const DelayColumn = AsColumn(DelayControl);
const ColourColumn = AsColumn(ColourPicker);

const ControlPanel = ({
  message,
  onClickSetMessage,
  delay,
  onDelayChange,
  colour,
  onColourChange,
}) => (
  <ControlPanelContainer>
    <MessageColumn
      title="Message Text"
      message={message}
      onClick={onClickSetMessage}
    />
    <DelayColumn
      title="Delay"
      value={delay}
      onChange={onDelayChange}
      min={15}
      max={1000}
    />
    <ColourColumn
      title="Colour"
      colour={colour}
      onChange={(newColour) => onColourChange(newColour.rgb)}
    />
  </ControlPanelContainer>
);

ControlPanel.propTypes = {
  message: PropTypes.string,
  onClickSetMessage: PropTypes.func,
  delay: PropTypes.number,
  onDelayChange: PropTypes.func,
  colour: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
  }),
  onColourChange: PropTypes.func,
};

ControlPanel.defaultProps = {
  message: '',
  onClickSetMessage: null,
  delay: 150,
  onDelayChange: null,
  colour: {
    r: 0,
    g: 0,
    b: 0,
  },
  onColourChange: null,
};

// translated strings so i can re-add em later
// 入力
// ディレイ
// 色
// 踊る！
// ＧＩＦを見せる
// ＧＩＦを作る
// 右クリック→セーブ

export default ControlPanel;
