import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import VerticalButton from './VerticalButton';

import sidebarBottom from '../img/sidebarBottomMirror.svg';

const DialogButtonContainer = styled.div`
    display: grid;
    grid-template-columns: 20px;
    grid-template-rows: auto auto 40px;
    position: fixed;
    top: 0;
    left: calc(100% - 20px);
`;

const SidebarTopRight = styled.div`
    grid-area: 1 / 1 / 1 / 1;
    border-left: thin solid yellow;
    height: 20px;
`;

const SidebarBgBottom = styled.div`
    grid-area: 3 / 1 / 3 / 1;
    background-image: url(${sidebarBottom});
    background-position: left top;
    background-repeat: no-repeat;
    height: 20px;
`;

const StyledVerticalButton = styled(VerticalButton)`
    grid-area: 2 / 1 / 2 / 1;
    transform: translateX(-9px);
`;

const DialogButton = ({ text, onClick }) => (
  <DialogButtonContainer>
    <SidebarTopRight />
    <StyledVerticalButton
      onClick={onClick}
      text={text}
    />
    <SidebarBgBottom />
  </DialogButtonContainer>
);

DialogButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

DialogButton.defaultProps = {
  text: '',
  onClick: null,
};

export default DialogButton;
