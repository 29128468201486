import React from 'react';

import Section from './Section';

import CgzPic from '../img/cgz.png';
import MspPic from '../img/msp.png';

const WebDesignTopic = () => (
  <>
    <Section
      title="Cool Goth Zone"
      img={CgzPic}
      link={{
        name: 'Visit',
        location: 'https://coolgoth.zone/',
      }}
    >
      This website. Initially created as a space to experiment with react, over several years
      it evolved into a homepage featuring a collection of small web apps and a portfolio.
      Written using as few additional libraries and toolkits as possible in order to
      better the learning experience of updating the site.
    </Section>
    <Section
      title="MediaShare+"
      img={MspPic}
      link={{
        name: 'Visit',
        location: 'https://mediashareplus.radiotv.solutions/',
      }}
    >
      A drop-in replacement for Streamlabs&apos; media share system, with improved reliability
      and an enhanced feature set. Includes a hold queue, playlist shuffling, media
      tagging and the ability to appoint other users as moderators, granting them the ability to
      curate the user&apos;s queues and control current media state (play/pause, position, volume,
      etc.) in real time.
    </Section>
  </>
);

// settin this aside til it's actually done,
/*
    <Section title="GCVT">
      Subcontracted for frontend work on the Green Connectivity Visualisation Tool, a data
      visualisation tool to help users identify something something something whatever
    </Section>
*/

export default WebDesignTopic;
