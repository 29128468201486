import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Container, { CornerContainer } from '../components/Container';

import { romaji } from './gojuuon.json';

import cardBorder from '../img/cardBorder.svg';
import cardBorderHover from '../img/cardBorderBlack.svg';

const AnswerTableContainer = styled(CornerContainer)`
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
`;

const AnswerTableColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 5px;

    &:first-child {
        margin-right: 0 !important;
    }

    &:last-child {
        margin-left: 0 !important;
    }
`;

const AnswerTableRowContainer = styled.div`
    position: relative;
    display: flex;
    margin-bottom: 5px;

    &:first-child {
        margin-top: 0;
    }
    
    &:last-child {
        margin-bottom: 0;
    }

    @media only screen and (max-height: 500px) {
        margin-bottom: -1px;
    }
`;

const AnswerTableCell = styled.div`
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    cursor: default;
    user-select: none;

    @media only screen and (max-width: 900px) {
        position: absolute;
        width: 30px;
        height: 30px;
        line-height: 30px;
    }

    @media only screen and (max-height: 500px) {
        width: 28px;
        height: 28px;
        line-height: 28px;
    }
`;

// TODO: the bit for making the boxes clickable seems janky, there's gotta be a better way
const AnswerTableSubcolContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    ${(props) => props.disabled && 'opacity: 0.5;'}
    background-color: rgba(0, 0, 0, 0.7);

    &:nth-child(2) {
        height: auto;
        margin-top: 23px;
    }

    ${(props) => props.clickable && `
        &, & * {
            cursor: pointer !important;
        }

        &:hover {
            background-color: yellow;
            color: black;
        }
    `}

    @media only screen and (max-width: 900px) {
        &:nth-child(2) {
            margin-top: 28px;
        }
    }

    @media only screen and (max-height: 500px) {
        &:nth-child(2) {
            margin-top: 14px;
        }
    }
`;

const AnswerTableCellBox = styled(AnswerTableCell)`
    margin-left: 5px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${cardBorder});

    ${(props) => props.clickable && `
        ${AnswerTableSubcolContainer}:hover &, ${AnswerTableSubcolContainer}:active & {
            background-image: url(${cardBorderHover});
        };
        `
}

    @media only screen and (max-width: 900px) {
        margin-left: 0;
        position: relative;
        background-size: cover;
    }

    @media only screen and (max-height: 500px) {
        margin-left: 0;
        position: relative;
        background-size: cover;
    }
`;

const AnswerTableRow = ({
  cellRomaji, cellKana, onDrop, clickable,
}) => (
  <AnswerTableRowContainer
    onDragOver={(ev) => ev.preventDefault()}
    onDrop={(ev) => onDrop(ev, cellRomaji)}
  >
    <AnswerTableCell>{ cellKana ? '' : cellRomaji }</AnswerTableCell>
    <AnswerTableCellBox clickable={clickable}>{ cellKana }</AnswerTableCellBox>
  </AnswerTableRowContainer>
);

AnswerTableRow.propTypes = {
  cellRomaji: PropTypes.string,
  cellKana: PropTypes.string,
  onDrop: PropTypes.func,
  clickable: PropTypes.bool,
};

AnswerTableRow.defaultProps = {
  cellRomaji: '',
  cellKana: '',
  onDrop: null,
  clickable: false,
};

const AnswerTableSubCol = ({
  placedCards, subCol, disabled, onClick, onDrop,
}) => (
  <AnswerTableSubcolContainer
    disabled={disabled}
    clickable={onClick && true}
    onClick={onClick && ((ev) => onClick(ev, subCol[0]))}
  >
    {
        subCol.map((cellRomaji) => (
          <AnswerTableRow
            key={cellRomaji}
            cellRomaji={cellRomaji}
            cellKana={cellRomaji in placedCards ? placedCards[cellRomaji] : ''}
            clickable={onClick && true}
            onDrop={onDrop}
          />
        ))
    }
  </AnswerTableSubcolContainer>
);

AnswerTableSubCol.propTypes = {
  placedCards: PropTypes.objectOf(PropTypes.string),
  subCol: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onDrop: PropTypes.func,
};

AnswerTableSubCol.defaultProps = {
  placedCards: {},
  subCol: [],
  disabled: false,
  onClick: null,
  onDrop: null,
};

const AnswerTable = ({
  placedCards, disabledColumns, onClick, onDrop,
}) => (
  <AnswerTableContainer>
    {
        romaji.map((col) => (
          <AnswerTableColumn key={col[0][0]}>
            {
                col.map((subCol) => (
                  <AnswerTableSubCol
                    key={subCol[0]}
                    placedCards={placedCards}
                    subCol={subCol}
                    disabled={disabledColumns.has(subCol[0])}
                    onClick={onClick}
                    onDrop={onDrop}
                  />
                ))
            }
          </AnswerTableColumn>
        ))
    }
  </AnswerTableContainer>
);

AnswerTable.propTypes = {
  placedCards: PropTypes.objectOf(PropTypes.string),
  disabledColumns: PropTypes.instanceOf(Set),
  onClick: PropTypes.func,
  onDrop: PropTypes.func,
};

AnswerTable.defaultProps = {
  placedCards: {},
  disabledColumns: new Set(),
  onClick: null,
  onDrop: null,
};

export default AnswerTable;
