import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../../components/Button';

import cornerTopLeft from '../../img/smallCornerTopLeft.svg';
import cornerTopRight from '../../img/smallCornerTopRight.svg';
import cornerBottomLeft from '../../img/smallCornerBottomLeft.svg';
import cornerBottomRight from '../../img/smallCornerBottomRight.svg';

const MessageControlContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

// at some point someone fucked with kosugi's metrics or something
// so now if i don't restrict the textarea width it goes wide as shit and breaks the flex
// TODO: fix that when i'm less fucking pissed about it
const MessageBox = styled.textarea`
    flex-grow: 1;
    text-align: center;
    resize: none;
    border: none;
    background: none;
    color: white;
    font-family: Kosugi, sans-serif;
    padding: 1px;
    padding-top: ${(props) => props.topOffset}px;
    background-repeat: no-repeat;
    background-position: left top, right top, left bottom, right bottom;
    background-image: url(${cornerTopLeft}),
                      url(${cornerTopRight}),
                      url(${cornerBottomLeft}),
                      url(${cornerBottomRight});
    background-color: rgba(0, 0, 0, 0.7);
    overflow: hidden;

    @media only screen and (min-width: 800px) {
        max-width: 212px;
    }
`;

// function for calculating the inner height of the given textarea
// works by (ugh) appending a new textarea to the dom, which mimics the style of the given textarea,
// then setting its height to 0 and using its scrollheight
// taken from https://stackoverflow.com/a/45252132 and adapted slightly
// TODO: this could definitely be optimised but like... its not exactly the bottleneck of the page
let buffer;
const getTextareaInnerHeight = (textarea) => {
  if (!buffer) {
    // initialise the buffer textarea and hide it as well as possible
    buffer = document.createElement('textarea');
    buffer.style.border = 'none';
    buffer.style.height = '0';
    buffer.style.overflow = 'hidden';
    buffer.style.padding = '0';
    buffer.style.position = 'absolute';
    buffer.style.left = '0';
    buffer.style.top = '0';
    buffer.style.zIndex = '-1';
    document.body.appendChild(buffer);
  }

  const computedStyle = window.getComputedStyle(textarea);
  const paddingLeft = parseInt(computedStyle.paddingLeft, 10);
  const paddingRight = parseInt(computedStyle.paddingRight, 10);
  let lineHeight = parseInt(computedStyle.lineHeight, 10);
  if (Number.isNaN(lineHeight)) lineHeight = parseInt(computedStyle.fontSize, 10);

  // mimic important bits of textarea styling (and maybe some unimportant bits re: font, oops)
  buffer.style.width = `${textarea.clientWidth - paddingLeft - paddingRight}px`;
  buffer.style.fontStyle = computedStyle.fontStyle;
  buffer.style.fontVariant = computedStyle.fontVariant;
  buffer.style.fontWeight = computedStyle.fontWeight;
  buffer.style.fontSize = computedStyle.fontSize;
  buffer.style.lineHeight = computedStyle.lineHeight;
  buffer.style.fontFamily = computedStyle.fontFamily;

  buffer.style.letterSpacing = computedStyle.letterSpacing;
  buffer.style.whiteSpace = computedStyle.whiteSpace;
  buffer.style.wordBreak = computedStyle.wordBreak;
  buffer.style.wordSpacing = computedStyle.wordSpacing;
  buffer.style.wordWrap = computedStyle.wordWrap;

  // put that letters in there
  buffer.value = textarea.value;

  // now its just the scrollHeight
  return buffer.scrollHeight;
};

const CenteredMessageBox = ({ value, onChange }) => {
  const [height, setHeight] = React.useState(0);
  const textAreaRef = React.useRef();

  React.useEffect(() => {
    setHeight(textAreaRef.current.clientHeight);
  }, []);

  let textareaInnerHeight;
  try {
    textareaInnerHeight = getTextareaInnerHeight(textAreaRef.current);
  } catch (e) {
    textareaInnerHeight = 0;
  }

  return (
    <MessageBox
      ref={textAreaRef}
      topOffset={Math.max(1, (height - textareaInnerHeight) / 2)}
      value={value}
      onChange={onChange}
    />
  );
};

CenteredMessageBox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

CenteredMessageBox.defaultProps = {
  value: '',
  onChange: null,
};

const MessageButton = styled(Button)`
    margin-top: 5px;
    width: 100%;
`;

const MessageControl = ({ message, onClick }) => {
  // it's a controlled input so we need this to be able to change it
  const [displayMessage, setDisplayMessage] = React.useState(message);

  return (
    <MessageControlContainer>
      <CenteredMessageBox
        value={displayMessage}
        onChange={(e) => setDisplayMessage(e.target.value)}
      />
      <MessageButton onClick={() => onClick(displayMessage)}>
        Dance!
      </MessageButton>
    </MessageControlContainer>
  );
};

MessageControl.propTypes = {
  message: PropTypes.string,
  onClick: PropTypes.func,
};

MessageControl.defaultProps = {
  message: '',
  onClick: null,
};

export default MessageControl;
