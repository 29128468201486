import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CornerContainer } from '../components/Container';
import Slider from '../components/Slider';

import formatTime from '../util';

import playButton from '../img/playButton.svg';
import pauseButton from '../img/pauseButton.svg';

const AudioPlayerContainer = styled(CornerContainer)`
    display: flex;
    align-items: center;

    & > * {
        margin-right: 10px;
    }

    & > :last-child {
        margin-right: 0;
    }
`;

const PlayPauseButton = styled.button.attrs({
  type: 'button',
})`
    background: none;
    border: none;
    padding: 0;
    min-width: 14px;
    height: 14px;
    cursor: pointer;
    background-image: url(${(props) => (props.isPlaying ? pauseButton : playButton)});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

const BadAudioWarning = styled.div`
    width: 100%;
    text-align: center;
`;

// this startin to look like it should be a class....
const AudioPlayer = ({
  src, audioContext, onSrcValidated, setRef,
}) => {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [duration, setDuration] = React.useState(0);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [srcValid, setSrcValid] = React.useState(false);
  const audioRef = React.useRef();

  // rig up the audio player to set component state when important stuff happens
  React.useEffect(() => {
    // pass the audio reference upwards
    setRef(audioRef);
    if (audioRef.current) {
      audioRef.current.addEventListener('loadedmetadata', () => {
        setDuration(
          Number.isNaN(audioRef.current.duration)
            ? 0
            : Math.floor(audioRef.current.duration),
        );
        setCurrentTime(0);
        setIsPlaying(false);
      });

      audioRef.current.addEventListener('timeupdate', () => {
        if (!Number.isNaN(audioRef.current.currentTime)) {
          if (Math.floor(audioRef.current.currentTime) !== currentTime) {
            setCurrentTime(Math.floor(audioRef.current.currentTime));
          }
        } else {
          setCurrentTime(0);
        }
      });

      audioRef.current.addEventListener('ended', () => {
        setIsPlaying(false);
        setCurrentTime(0);
      });

      // these two callbacks are for reporting back whether the audio actually loaded or not
      // if it loads, great! if it doesn't, that's fine too!
      // i just show an error and pass the value back up so the app can disable the encoding panel
      audioRef.current.addEventListener('error', () => {
        setSrcValid(false);
        onSrcValidated(false);
      });

      audioRef.current.addEventListener('canplay', () => {
        setSrcValid(true);
        onSrcValidated(true);
      });
    }
  }, []);

  React.useEffect(() => {
    audioRef.current.load();
  }, [src]);

  let content;
  if (!src || src === '') {
    content = <BadAudioWarning>No audio file selected.</BadAudioWarning>;
  } else if (!srcValid) {
    content = <BadAudioWarning>Invalid file type.</BadAudioWarning>;
  } else {
    content = (
      <>
        <PlayPauseButton
          isPlaying={isPlaying}
          onClick={() => {
            // resume the audiocontext if one was passed in
            if (audioContext) audioContext.resume();
            if (src === '') return;
            if (isPlaying) {
              audioRef.current.pause();
            } else {
              audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
          }}
        />
        <Slider
          value={currentTime * 1000}
          min={0}
          max={duration * 1000}
          onChange={(val) => {
            // another unavoidable param reassign... audio really do just be that way
            // eslint-disable-next-line no-param-reassign
            audioRef.current.currentTime = val / 1000;
            setCurrentTime(val / 1000);
          }}
          onMouseDown={() => {
            if (isPlaying) audioRef.current.pause();
          }}
          onMouseUp={() => {
            if (isPlaying) audioRef.current.play();
          }}
        />
        <span>
          {formatTime(currentTime * 1000)}
                    &nbsp;/&nbsp;
          {formatTime(duration * 1000)}
        </span>
      </>
    );
  }

  return (
    <AudioPlayerContainer>
      <audio
        src={src}
        ref={audioRef}
      >
        <track kind="captions" />
      </audio>
      {content}
    </AudioPlayerContainer>
  );
};

// ngl this looks wack but if i don't do this safari shits itself so,
AudioPlayer.propTypes = {
  src: PropTypes.string,
  audioContext: PropTypes.oneOfType([
    PropTypes.instanceOf(window.AudioContext),
    PropTypes.instanceOf(window.webkitAudioContext),
  ]),
  onSrcValidated: PropTypes.func,
  setRef: PropTypes.func,
};

AudioPlayer.defaultProps = {
  src: '',
  audioContext: undefined,
  onSrcValidated: () => {},
  setRef: () => {},
};

export default AudioPlayer;
