import React from 'react';

import Section from './Section';

import { InlineLink } from '../components/Link';

import BoyzPic from '../img/boyznitedx.png';
import MandrillPic from '../img/mandrillmaze.png';
import SoundpackPic from '../img/slamjam.png';
import MinotaurPic from '../img/minotaur.png';
import PolybotPic from '../img/polybot.png';

// TODO: image, links
const OtherTopic = () => (
  <>
    <Section
      title="Boyz Nite DX"
      img={BoyzPic}
      link={{ name: 'See It in Action (Video)', location: '/files/boyznitedx.webm' }}
    >
      Described as &quot;The Best Way to Watch Videos Online&quot;, Boyz Nite DX is a CyTube
      extension which allows effects, scenes and overlays to be applied to any page element,
      and synchronises them to all viewers to &quot;enhance&quot; the viewing experience.
      Client code written in raw JavaScript (~8kB minified),
      with a simple node.js backend used for state management and user synchronisation via
      websocket. Made in collaboration with
      {' '}
      <InlineLink href="https://twitter.com/curvebreaker/">curvebreaker</InlineLink>
      ,
      original concept and additional CSS by
      {' '}
      <InlineLink href="https://twitter.com/technoabyss">technoabyss</InlineLink>
      .
    </Section>
    <Section
      title="Mandrill Maze (L4D2 & TF2 Map)"
      img={MandrillPic}
      link={[
        { name: 'TF2 Map (Download)', location: '/files/ctf_mandrillmaze_b2.bsp' },
        {
          name: 'L4D2 Map (Steam Workshop)',
          location: 'https://steamcommunity.com/sharedfiles/filedetails/?id=150860250',
        },
      ]}
    >
      Originally created as a map for Left 4 Dead 2&apos;s survival gamemode, this map included
      an infinite loop to simulate the video that inspired its creation. Later ported to Team
      Fortress 2 as a Capture the Flag map, where its tight hallways and repetitive design earned
      it a reputation as a chaotic nightmare. Inadvertently spawned an entire genre of TF2
      gimmick maps after the map&apos;s assets appeared in TF2Ware.
    </Section>
    <Section
      title="Superslam Charger Soundpack (L4D2)"
      img={SoundpackPic}
      link={{ name: 'Download (ZIP Archive)', location: '/files/SLAM_JAM_charger.zip' }}
    >
      A Left for Dead 2 sound pack that enhances the charger&apos;s existing sounds with samples
      from Quad City DJs&apos; opus, &quot;Space Jam&quot;. Created before the existence of Steam
      Workshop, and as such must be installed manually due to quirks in Workshop&apos;s handling
      of custom audio.
    </Section>
    <Section
      title="Munerator & Minotaur"
      img={MinotaurPic}
    >
      Punishment turned public entertainment, Munerator is a Discord bot which allows moderators
      to send a user to a designated &quot;arena&quot; channel to do battle against a second bot,
      the Minotaur. If (when) the user is slain, they are trapped in a timeout channel to consider
      their words and deeds - or just outright banned if their crimes warrant it. Features
      artwork from the incredibly talented
      {' '}
      <InlineLink href="https://twitter.com/Logblaster/">Log</InlineLink>
      .
    </Section>
    <Section
      title="Polybot"
      img={PolybotPic}
    >
      A bot designed as a parody of common Discord bots such as Septapus, with the intention of
      making any server it is present on worse. Includes commands such as &quot;.large&quot;
      (a &quot;high-quality&quot; emoji upscaler), &quot;.dance&quot; (an early demo of this
      site&apos;s dancing letter GIF generator), and &quot;.requiem&quot; (swaps the names of
      all users in a voice channel at random).
    </Section>
  </>
);

export default OtherTopic;
