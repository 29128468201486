import React from 'react';

import Section from './Section';

import BsodPic from '../img/bsod.png';
import BeatsPic from '../img/beats.png';
import ParadePic from '../img/eventparade.png';

const StreamToolsTopic = () => (
  <>
    <Section
      title="BSOD Battleships"
      img={BsodPic}
      link={{
        name: 'See It in Action (YouTube)',
        location: 'https://www.youtube.com/watch?v=ZHe6Edl1Uro&t=317s',
      }}
    >
      A high-stakes game of chance. Viewers donate to fire shots at the streamer&apos;s game
      board in an attempt to sink their fleet of ships. If successful, the application gives a 5
      second warning before manually triggering a blue screen of death, crashing the
      streamer&apos;s computer and ending the stream instantly. React frontend, node.js backend.
    </Section>
    <Section
      title="Twitch Type Beats"
      img={BeatsPic}
      link={{
        name: 'See It in Action (YouTube)',
        location: 'https://www.youtube.com/watch?v=CB4kgFtFU0I&t=3482s',
      }}
    >
      A DJ set like no other, Twitch Type Beats presents a set of 17 samples divided into beat,
      bass, melody and shaker sections, and allows stream viewers to control the music directly
      through votes cast in the stream chat. React frontend using tone.js to synchronise samples.
      Node.js backend.
    </Section>
    <Section
      title="Twitch Event Parade"
      img={ParadePic}
    >
      A simple overlay for displaying incoming subscriptions and bit donations in the form of a
      parade of notifications, scrolling left to right across the stream. Includes special
      handling for &quot;Hype Train&quot; events, causing the notification parade to transform
      into a train which rattles across the screen until the event ends. Jquery frontend,
      node.js backend.
    </Section>
  </>
);

export default StreamToolsTopic;
