import styled from 'styled-components';

// this is split out so it can be reused for links
const buttonStyle = `
    font-family: 'Kosugi', sans-serif;
    font-size: 16px;
    width: 110px;
    height: 30px;
    cursor: pointer;
    border: 1px solid yellow;
    color: white;
    background: none;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
    line-height: 28px;
    box-sizing: border-box;
    text-decoration: none;
    padding: 0 8px;

    &:hover:enabled, &:active:enabled {
        color: black;
        background: yellow;
    }

    &:disabled {
        cursor: not-allowed;
        color: rgba(255, 255, 255, .5);
        border-color: rgba(255, 255, 0, .5);
        background-color: rgba(0, 0, 0, .35);
    }
`;

const Button = styled.button`
    ${buttonStyle}
`;

export {
  Button as default,
  buttonStyle,
};
