import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// this looks a little goofy but for some reason react-color doesn't seem to be tree-shakeable
import CustomPicker from 'react-color/lib/Custom';
import Hue from 'react-color/lib/components/common/Hue';
import Saturation from 'react-color/lib/components/common/Saturation';
import color from 'react-color/lib/helpers/color';

import {
  NumberInput,
  HexInput,
} from './Input';

const PickerContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const SaturationContainer = styled.div`
    width: 100px;
    position: relative;
`;

const HueContainer = styled.div`
    width: 10px;
    position: relative;
    margin-left: 10px;
`;

const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
`;

const FieldContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Label = styled.span`
    text-transform: upperCase;
    margin-right: 10px;
`;

const RgbInput = styled(NumberInput).attrs({
  min: 0,
  max: 255,
})`
    margin-right: 10px;
    width: 40px;
`;

const ColourHexInput = styled(HexInput)`
    width: 60px;
`;

const SaturationPointer = styled.div`
    width: 4px;
    height: 4px;
    border: 1px solid yellow;
    transform: translate(-50%, -50%);
    cursor: pointer;
`;

const HuePointer = styled.div`
    height: 4px;
    width: 10px;
    border: 1px solid yellow;
    background: black;
    transform: translate(-1px, -50%);
    cursor: pointer;
`;

const Field = ({
  rgb, hex, label, onChange,
}) => (
  <FieldContainer>
    <Label>
      {label}
    </Label>
    {
        label === '#' ? (
          <ColourHexInput
            value={hex}
            onChange={(e) => onChange(label, e)}
          />
        ) : (
          <RgbInput
            value={rgb[label]}
            onChange={(e) => onChange(label, e)}
          />
        )
    }
  </FieldContainer>
);

Field.propTypes = {
  rgb: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
  }),
  hex: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

Field.defaultProps = {
  rgb: {
    r: 0,
    g: 0,
    b: 0,
  },
  hex: '0',
  onChange: null,
};

const ColourFields = ({ rgb, hex, onChange }) => {
  const handleFields = (label, e) => {
    if (label === '#') {
      if (color.isValidHex(e.target.value)) {
        onChange({
          hex: e.target.value,
          source: 'hex',
        }, e);
      }
    } else if (label === 'r' || label === 'g' || label === 'b') {
      onChange({
        r: label === 'r' ? e.target.value : rgb.r,
        g: label === 'g' ? e.target.value : rgb.g,
        b: label === 'b' ? e.target.value : rgb.b,
        source: 'rgb',
      }, e);
    }
  };

  return (
    <>
      <Field
        rgb={rgb}
        label="r"
        onChange={handleFields}
      />
      <Field
        rgb={rgb}
        label="g"
        onChange={handleFields}
      />
      <Field
        rgb={rgb}
        label="b"
        onChange={handleFields}
      />
      <Field
        hex={hex.replace('#', '')}
        label="#"
        onChange={handleFields}
      />
    </>
  );
};

ColourFields.propTypes = {
  rgb: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
  }),
  hex: PropTypes.string,
  onChange: PropTypes.func,
};

ColourFields.defaultProps = {
  rgb: {
    r: 0,
    g: 0,
    b: 0,
  },
  hex: '0',
  onChange: null,
};

const ColourPicker = ({
  rgb, hsl, hsv, hex, onChange,
}) => (
  <PickerContainer>
    <SaturationContainer>
      <Saturation
        hsl={hsl}
        hsv={hsv}
        onChange={onChange}
        pointer={SaturationPointer}
      />
    </SaturationContainer>
    <HueContainer>
      <Hue
        hsl={hsl}
        direction="vertical"
        onChange={onChange}
        pointer={HuePointer}
      />
    </HueContainer>
    <FieldsContainer>
      <ColourFields
        rgb={rgb}
        hex={hex}
        onChange={onChange}
      />
    </FieldsContainer>
  </PickerContainer>
);

ColourPicker.propTypes = {
  rgb: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
  }),
  hsv: PropTypes.shape({
    h: PropTypes.number,
    s: PropTypes.number,
    v: PropTypes.number,
  }),
  hsl: PropTypes.shape({
    h: PropTypes.number,
    s: PropTypes.number,
    l: PropTypes.number,
  }),
  hex: PropTypes.string,
  onChange: PropTypes.func,
};

ColourPicker.defaultProps = {
  rgb: {
    r: 0,
    g: 0,
    b: 0,
  },
  hsv: {
    h: 0,
    s: 0,
    v: 0,
  },
  hsl: {
    h: 0,
    s: 0,
    l: 0,
  },
  hex: '0',
  onChange: null,
};

// i wrote the rest of the code spelling it "colour" and i'm not about to switch
// this is so i don't waste any more time over a stupid typo!!!
const ColourTranslator = (Wrapped) => ({ colour, ...other }) => (
  <Wrapped
    color={colour}
    // kinda need to prop spread here,
    // eslint-disable-next-line
    {...other}
  />
);

export default ColourTranslator(CustomPicker(ColourPicker));
