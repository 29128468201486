import './vendor/dragdroptouch/DragDropTouch';

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled, { createGlobalStyle } from 'styled-components';
import {
  BrowserRouter,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
// import { isMobile } from 'react-device-detect';
import MediaQuery from 'react-responsive';

import { MobileBackground } from './Background';
import Landing from './Landing';
import DanceApp from './dance/DanceApp';
import KanaApp from './kana/KanaApp';
import SoundApp from './hdsound/SoundApp';
import WorksApp from './works/Works';
import AboutApp from './about/About';
import Sidebar from './Sidebar';
import HelpButton from './HelpButton';
import MobileMenu from './MobileMenu';

const GlobalStyle = createGlobalStyle`
    body {
        width: 100%;
        height: 100vh;
        margin: 0;
        font-family: Kosugi, Roboto Condensed, sans-serif;
        background: #111;
    }

    #root {
        width: 100%;
        height: 100%;
        margin: 0;
    }
`;

// this adds a 40px buffer area wherever the menu/help buttons will be
// so that the sidebar and help buttons won't overlap when zoomed in real far
// it also sets the page's scroll stuff so it doesn't act fucked when the menu is up
// TODO: grabbing the scroll value right in here is... not the most "react" way to do this...
const ContentContainer = styled.div`
    position: relative;
    display: inline-flex;
    height: 100%;
    min-width: 100%;
    box-sizing: border-box;
    padding: 10px 40px;
    height: 100%;
    ${(props) => props.lockScroll && `
        position: fixed;
        top: -${props.lockPos}px;
        padding-right: ${40 + (window.innerWidth - document.documentElement.clientWidth)}px;
    `}
    
    @media only screen and (max-width: 500px) {
        padding: 40px 10px 0 10px;
    }
`;

const TheContent = () => {
  // state has to be lifted up here to fix scrolling issues with the background
  // pretty irritating but whatever
  const [menuVisible, setMenuVisible] = React.useState(false);
  const containerRef = React.useRef(null);
  const [lockPos, setLockPos] = React.useState(0);
  const history = useLocation();

  // mobile devices should use the static svg background instead
  // tragically i'm having to disable the animated background.
  // its too fucking dope for modern machines to handle...
  // const TheBackground = isMobile ? MobileBackground : Background;
  const TheBackground = MobileBackground;

  // this sets the lock position to 0 when the page changes
  useEffect(() => { setLockPos(0); }, [history]);

  // this scrolls to back to the lock position when the menu is dismissed
  // note use of useLayoutEffect here to avoid flicker
  React.useLayoutEffect(() => {
    if (!menuVisible) {
      window.scrollTo(0, lockPos);
    }
  }, [menuVisible]);

  return (
    <>
      <GlobalStyle />
      <TheBackground />
      <ContentContainer
        ref={containerRef}
        lockScroll={menuVisible}
        lockPos={lockPos}
      >
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route path="/kana">
            <KanaApp />
          </Route>
          <Route path="/dance">
            <DanceApp />
          </Route>
          <Route path="/sound">
            <SoundApp />
          </Route>
          <Route path="/works">
            <WorksApp />
          </Route>
          <Route path="/about">
            <AboutApp />
          </Route>
        </Switch>
      </ContentContainer>
      <MediaQuery maxDeviceWidth={500}>
        {
          // if the viewport gets real narrow switch to the mobile menu
          (matches) => (matches
            ? (
              <MobileMenu
                visible={menuVisible}
                onClick={() => {
                  if (!menuVisible) {
                    setLockPos(window.scrollY);
                  }
                  setMenuVisible(!menuVisible);
                }}
              />
            )
            : (
              <>
                <Sidebar
                  visible={menuVisible}
                  onClick={() => {
                    // if the menu is about to be shown we gotta record the scroll position
                    if (!menuVisible) {
                      setLockPos(window.scrollY);
                    }
                    setMenuVisible(!menuVisible);
                  }}
                />
                <HelpButton />
              </>
            ))
        }
      </MediaQuery>
    </>
  );
};

ReactDOM.render(<BrowserRouter><TheContent /></BrowserRouter>, document.getElementById('root'));
