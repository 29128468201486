import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CornerContainer } from '../components/Container';
import { Subheader } from '../components/Headers';
import { WideLink } from '../components/Link';

const Image = styled.img`
    width: auto;
    max-width: 100%;
    max-height: 200px;
    margin-top: .4em;
`;

const ProseContainer = styled.p`
    text-align: left;
    line-height: 1.375;
    
    & > :first-child {
        margin-top: 0;
    }
    
    & > :last-child {
        margin-bottom: 0;
    }
    
    &:last-child {
        margin-bottom: 0;
    }
`;

const Section = ({
  title, img, link, children,
}) => {
  let links;

  if (!link) {
    links = undefined;
  } else if (Array.isArray(link)) {
    links = link.map((l) => <WideLink key={l.name} href={l.location}>{l.name}</WideLink>);
  } else {
    links = <WideLink href={link.location}>{link.name}</WideLink>;
  }

  return (
    <CornerContainer>
      <Subheader>{title}</Subheader>
      { img && <Image alt={title} src={img} /> }
      <ProseContainer>
        {children}
      </ProseContainer>
      {links}
    </CornerContainer>
  );
};

// let's see if this works :)

Section.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string,
  link: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
      location: PropTypes.string,
    }),
    PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      location: PropTypes.string,
    })),
  ]),
  children: PropTypes.node.isRequired,
};

Section.defaultProps = {
  img: undefined,
  link: undefined,
};

export default Section;
