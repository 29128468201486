import React from 'react';
import styled from 'styled-components';

import AnswerTable from './AnswerTable';
import CardPool from './CardPool';
import ModeSelector from './ModeSelector';
import WinMessage from './WinMessage';
import Toolbar from './Toolbar';

import { CornerContainer, ProseContainer } from '../components/Container';
import { Header } from '../components/Headers';
import Button from '../components/Button';

import { romaji, hiragana, katakana } from './gojuuon.json';

const AppContainerInner = styled.div`
    display: inline-flex;
    flex-direction: column;
    margin: auto;
    padding-bottom: 10px;

    & > * {
        margin-bottom: -1px;
    }

    & > :last-child {
        margin-bottom: 0;
    }
`;

const StyledButton = styled(Button)`
    width: 100%;
`;

const PreludeContainer = styled(ProseContainer)`
    text-align: center;
    max-width: 760px;
`;

const HeaderContainer = styled(CornerContainer)`
    @media only screen and (max-height: 640px) {
        display: none;
    }
`;

// returns a shuffled copy of an array
const shuffle = (array) => {
  const shuffled = [...array];

  for (let i = shuffled.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }

  return shuffled;
};

const NoKanaWarningContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
`;

const NoKanaWarningMessage = styled.div`
    border: 1px solid yellow;
    color: white;
    height: 28px;
    line-height: 28px;
    padding: 0 20px;
    border-radius: 4px;
`;

const NoKanaWarning = () => (
  <NoKanaWarningContainer>
    <NoKanaWarningMessage>
      Please select at least one column to play.
    </NoKanaWarningMessage>
  </NoKanaWarningContainer>
);

class KanaApp extends React.Component {
  // noting this because it caught me out and i wrote the fucking thing:
  // cards is an array because we dummy out used cards rather than removing them
  // disabledColumns is fine as a set tho
  constructor(props) {
    super(props);

    this.state = {
      placedCards: {},
      cards: [],
      disabledColumns: new Set([]),
      startTime: 0,
      elapsedTime: 0,
      kanaSet: '',
      appState: 'MODE_SELECT',
      prelude: true,
    };
  }

  onClick(ev, subCol) {
    this.setState((prevState) => {
      const newDisabledColumns = new Set(prevState.disabledColumns);
      if (prevState.disabledColumns.has(subCol)) {
        newDisabledColumns.delete(subCol);
      } else {
        newDisabledColumns.add(subCol);
      }
      return { disabledColumns: newDisabledColumns };
    });
  }

  onDrop(ev, targetRomaji) {
    ev.preventDefault();
    const draggedRomaji = ev.dataTransfer.getData('text/plain');

    if (draggedRomaji === targetRomaji) {
      this.setState((prevState) => {
        // figure out what card was dragged
        const card = prevState.cards.find((c) => c && (c.romaji === draggedRomaji));

        // update state
        const newSlots = { ...prevState.placedCards };
        newSlots[card.romaji] = card.kana;

        const newCards = [...prevState.cards];
        const newCard = {
          romaji: card.romaji,
          kana: card.kana,
          placed: true,
        };

        newCards.splice(prevState.cards.indexOf(card), 1, newCard);

        // check if all cards are now placed (ie. if the "game" is over)
        const completeYet = newCards.every((c) => c.placed);
        // stop the timer if so
        if (completeYet) clearInterval(this.timer);
        const newAppState = completeYet ? 'COMPLETE' : prevState.appState;

        return { placedCards: newSlots, cards: newCards, appState: newAppState };
      });
    }
  }

  startGame(newKanaSet) {
    const allKana = (newKanaSet === 'KATAKANA' ? katakana : hiragana);
    const allRomaji = romaji.flat();

    // remove kana from disabled columns
    // ooohooooo this is janky
    const enabledKana = allKana.filter((kanaCol, idx) => {
      const { disabledColumns } = this.state;
      return !disabledColumns.has(allRomaji[idx][0]);
    }).flat();
    const enabledRomaji = allRomaji.filter((romajiCol) => {
      const { disabledColumns } = this.state;
      return !disabledColumns.has(romajiCol[0]);
    }).flat();

    let newCards = enabledRomaji.map((r, idx) => (
      {
        romaji: r,
        kana: enabledKana[idx],
        placed: false,
      }
    ));
    newCards = shuffle(newCards);

    this.timer = setInterval(() => {
      this.setState((prevState) => ({ elapsedTime: (Date.now() - prevState.startTime) }));
    }, 1000);

    this.setState({
      placedCards: {},
      cards: newCards,
      kanaSet: newKanaSet,
      startTime: Date.now(),
      elapsedTime: 0,
      appState: 'IN_PROGRESS',
    });
  }

  restart() {
    clearInterval(this.timer);

    const { kanaSet } = this.state;
    this.startGame(kanaSet);
  }

  reset() {
    clearInterval(this.timer);

    this.setState({
      placedCards: {},
      cards: [],
      startTime: 0,
      elapsedTime: 0,
      appState: 'MODE_SELECT',
    });
  }

  render() {
    let answerTable;
    const {
      appState, cards, placedCards, disabledColumns, elapsedTime, prelude,
    } = this.state;
    if (appState === 'MODE_SELECT') {
      answerTable = (
        <AnswerTable
          placedCards={placedCards}
          disabledColumns={disabledColumns}
          onClick={(ev, subCol) => this.onClick(ev, subCol)}
        />
      );
    } else {
      answerTable = (
        <AnswerTable
          placedCards={placedCards}
          disabledColumns={disabledColumns}
          onDrop={(ev, c) => this.onDrop(ev, c)}
        />
      );
    }

    let centerPanel;
    switch (appState) {
      case 'MODE_SELECT':
        console.log(disabledColumns.size, katakana.length);
        if (disabledColumns.size === katakana.length) {
          centerPanel = <NoKanaWarning />;
        } else {
          centerPanel = (
            <ModeSelector
              onClick={(mode) => this.startGame(mode)}
            />
          );
        }
        break;
      case 'IN_PROGRESS':
        centerPanel = (
          <CardPool
            cards={cards}
            onDragStart={(ev, r) => ev.dataTransfer.setData('text/plain', r)}
          />
        );
        break;
      case 'COMPLETE':
        centerPanel = <WinMessage clearTime={elapsedTime} />;
        break;
      default:
        console.log(`Undefined state ${appState}! How did you manage this?`);
    }

    return (
      <>
        <AppContainerInner>
          { prelude ? (
            <>
              <CornerContainer>
                <Header>Kana</Header>
              </CornerContainer>
              <PreludeContainer>
                <p>
                  The Kana app is optimised for screen resolutions of 650x360px and larger.
                </p>
                <p>
                  If you have entered the Cool Goth Zone on a handheld device, it is strongly
                  advised that you turn the device sideways before continuing to the Kana app.
                </p>
                <StyledButton
                  onClick={() => this.setState({ prelude: false })}
                >
                  Continue
                </StyledButton>
              </PreludeContainer>
            </>
          ) : (
            <>
              <HeaderContainer>
                <Header>Kana</Header>
              </HeaderContainer>
              { answerTable }
              <CornerContainer>
                { centerPanel }
              </CornerContainer>
              <Toolbar
                time={elapsedTime}
                onRestartClick={() => this.restart()}
                onResetClick={() => this.reset()}
                disabled={appState === 'MODE_SELECT'}
              />
            </>
          )}
        </AppContainerInner>
      </>
    );
  }
}

export default KanaApp;
