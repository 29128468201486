import React from 'react';
import styled from 'styled-components';

import { CornerContainer } from './components/Container';
import { Header, Subheader } from './components/Headers';

const LandingContainer = styled.div`
    margin: auto;
    padding-bottom: 10px;
`;

const StyledCornerContainer = styled(CornerContainer)`
    margin-bottom: -1px;
    font-size: 16px;
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;

    @media only screen and (max-width: 650px) {
        font-size: 12px;
    }
`;

const Landing = () => (
  <LandingContainer>
    <StyledCornerContainer>
      <Header>Cool Goth Zone</Header>
    </StyledCornerContainer>
    <StyledCornerContainer>
      <Subheader>
        Welcome to &quot;Cool Goth Zone&quot;.
        <br />
        This &quot;web site&quot; remains under &quot;construction&quot;.
        <br />
        Until then, please enjoy the &quot;hyper links&quot; in the &quot;menu&quot;.
      </Subheader>
    </StyledCornerContainer>
  </LandingContainer>
);

export default Landing;
