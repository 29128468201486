import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledButton = styled.button`
    padding: 5px 0 3px 0;
    border-top: 1px solid yellow;
    border-left: 0;
    border-bottom: 1px solid yellow;
    border-right: 0;
    margin: 0;
    text-transform: uppercase;
    cursor: pointer;
    color: yellow;
    font-size: 1em;
    font-family: 'kosugi';
    text-align: center;
    background: none;
    width: 19px;

    &:clicked {
        border: 0;
        padding: 5px 0 3px 0;
    }
`;

const VerticalSpan = styled.span`
    writing-mode: vertical-lr;
    text-orientation: upright;
    line-height: 0;
`;

const VerticalButton = forwardRef(({ onClick, text, className }, ref) => (
  <StyledButton
    onClick={onClick}
    className={className}
    ref={ref}
  >
    <VerticalSpan>{ text }</VerticalSpan>
  </StyledButton>
));

VerticalButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

VerticalButton.defaultProps = {
  text: '',
  onClick: null,
  className: '',
};

export default VerticalButton;
