import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { MenuRouterLink } from './components/RouterLink';
import SublinkList from './SubLinkList';

// the link lists used in both menus
// (they're actually identical in both code and styling)

const links = [
  { to: '/', label: 'Home', exact: true },
  {
    label: 'Apps',
    sublinks: [
      { to: '/kana', label: 'Kana' },
      { to: '/dance', label: 'Dance' },
      { to: '/sound', label: 'Sound' },
    ],
  },
  {
    label: 'Works',
    sublinks: [
      { to: '/works/websites', label: 'Websites' },
      { to: '/works/streamtools', label: 'Stream Tools' },
      { to: '/works/games', label: 'Games' },
      { to: '/works/other', label: 'Other' },
    ],
  },
  { to: '/about', label: 'About' },
  { to: { pathname: 'https://ko-fi.com/U7U66FJ0L' }, label: 'Support', target: '_blank' },
];

const StyledLinkList = styled.ul`
    grid-area: 3 / 1 / 5 / 1;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    list-style-type: none;
    width: 100%;
    margin: 0;
    padding: 0;
`;

const LinkListItem = styled.li`
    display: flex;
    align-items: center;

    &::before, &::after {
        content: "";
        display: inline-block;
        width: 20px;
        border-top: thin solid yellow;
    }
`;

// TODO: should the menu close if the link opens in a new tab...? probably not? not sure tho
const LinkList = ({ dummy, onClick }) => (
  <StyledLinkList>
    {
        links.map((link) => {
          if (!(link.sublinks)) {
            return (
              <LinkListItem key={link.label}>
                <MenuRouterLink
                  to={link.to}
                  label={link.label}
                  exact={link.exact}
                  target={link.target}
                  dummy={dummy}
                  onClick={onClick}
                />
              </LinkListItem>
            );
          }

          return (
            <SublinkList
              key={link.label}
              label={link.label}
              sublinks={link.sublinks}
              dummy={dummy}
              onClick={onClick}
            />
          );
        })
      }
  </StyledLinkList>
);

LinkList.propTypes = {
  dummy: PropTypes.bool,
  onClick: PropTypes.func,
};

LinkList.defaultProps = {
  dummy: false,
  onClick: null,
};

export default LinkList;
