import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NumberInput } from './Input';
import { ExponentialSlider } from '../../components/Slider';

const DelayInput = styled(NumberInput)`
    width: 40px;
    margin-right: 5px;
`;

const DelayContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const LabelledSliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    margin-top: 3px;
`;

const StyledSlider = styled(ExponentialSlider)`
    box-sizing: border-box;
    margin: 4px 0;
    padding-left: 25px;
    padding-right: 25px;
`;

const SliderLabelContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const SliderLabel = styled.span`
    width: 50px;
`;

const LabelledSlider = ({
  value, min, max, onChange,
}) => (
  <LabelledSliderContainer>
    <StyledSlider
      value={value}
      min={min}
      max={max}
      onChange={(e) => {
        onChange(e);
      }}
    />
    <SliderLabelContainer>
      <SliderLabel>{min}</SliderLabel>
      <SliderLabel>{max}</SliderLabel>
    </SliderLabelContainer>
  </LabelledSliderContainer>
);

LabelledSlider.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
};

LabelledSlider.defaultProps = {
  value: 0,
  min: 0,
  max: 0,
  onChange: null,
};

const DelayControl = ({
  value, min, max, onChange,
}) => (
  <DelayContainer>
    <div>
      <DelayInput
        value={value}
        min={min}
        max={max}
        onChange={(e) => onChange(Number(e.target.value))}
      />
      ms
    </div>
    <LabelledSlider
      value={value}
      min={min}
      max={max}
      onChange={onChange}
    />
  </DelayContainer>
);

DelayControl.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
};

DelayControl.defaultProps = {
  value: 0,
  min: 0,
  max: 0,
  onChange: null,
};

export default DelayControl;
