import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '../components/Dialog';

const HelpDialog = ({ onClick }) => (
  <Dialog
    title="Welcome to Sound Zone"
    buttonText="Return"
    onClick={onClick}
    onOverlayClick={onClick}
    visible
  >
    <p>Select an audio file with the &quot;Browse&quot; button.</p>
    <p>Use the audio player to hear the file in HD sound.</p>
    <p>
      HD sound can be saved by selecting a file format
      and clicking the &quot;Encode&quot; button.
    </p>
    <p>When encoding is complete, click the &quot;Download&quot; button to save the file.</p>
    <p>Please enjoy the quality of HD sound.</p>
  </Dialog>
);

HelpDialog.propTypes = {
  onClick: PropTypes.func,
};

HelpDialog.defaultProps = {
  onClick: null,
};

export default HelpDialog;
