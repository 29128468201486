import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MAX_FRAME_WIDTH = 640;
const MAX_FRAME_HEIGHT = 360;

// infuriatingly there's no real way to maintain aspect ratios in css without hacks
// so here we're using the known max dimensions to set max width and height to the
// exact percentage values needed to maintain aspect ratio while still allowing the canvas to shrink
const Canvas = styled.canvas`
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    transform: translate(-50%, -50%);
    max-width: ${(props) => (props.width / MAX_FRAME_WIDTH) * 95}%;
    max-height: ${(props) => (props.height / MAX_FRAME_HEIGHT) * 95}%;
`;

// pulling canvas out like this ensures it only gets updated when it *really* needs to be
class PureCanvas extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { width, height } = this.props;
    return nextProps.width !== width || nextProps.height !== height;
  }

  render() {
    const { width, height, canvasRef } = this.props;
    return (
      <Canvas
        width={width}
        height={height}
        ref={canvasRef}
      />
    );
  }
}

PureCanvas.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  canvasRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
};

export default PureCanvas;
