import React from 'react';
import PropTypes from 'prop-types';

import PureCanvas from './PureCanvas';

// component that draws the dancing letters
// this one has a bunch of mutable props, as a functional component it was awful
class AnimatedCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.#canvas = React.createRef();
  }

  componentDidMount() {
    this.start();
  }

  componentDidUpdate(prevProps) {
    const { frames } = this.props;
    // if the frames change, draw white so we don't see the backdrop
    if (frames !== prevProps.frames) {
      const context = this.#canvas.current.getContext('2d');
      context.fillStyle = 'rgb(255, 255, 255)';
      context.fillRect(0, 0, this.#canvas.current.width, this.#canvas.current.height);
    }
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.#request);
  }

    // TODO: again, why the fuck eslint wants this here?
    #canvas;

    #frameIdx = 0;

    #timeSinceLastFrame = 0;

    #previousTime;

    #request;

    start() {
      this.#request = requestAnimationFrame((t) => this.animate(t));
    }

    // calc delta, call the draw function, and loop
    animate(currentTime) {
      if (this.#previousTime !== undefined) {
        const delta = currentTime - this.#previousTime;
        this.draw(delta);
      }

      this.#previousTime = currentTime;
      this.#request = requestAnimationFrame((t) => this.animate(t));
    }

    draw(delta) {
      const { frames } = this.props;
      if (frames && frames.length > 0) {
        let timeSinceLastFrame = this.#timeSinceLastFrame + delta;
        const { delay } = this.props;
        if (timeSinceLastFrame >= delay) {
          const numFrames = frames.length;
          const { width, height } = this.props;

          // skip frames to catch up if necessary
          const framesToAdvance = Math.floor(timeSinceLastFrame / delay);
          this.#frameIdx = (this.#frameIdx + framesToAdvance) % numFrames;

          // draw the new frame
          const context = this.#canvas.current.getContext('2d');
          context.clearRect(0, 0, width, height);
          context.drawImage(frames[this.#frameIdx], 0, 0);

          timeSinceLastFrame -= delay * framesToAdvance;
        }
        this.#timeSinceLastFrame = timeSinceLastFrame;
      }
    }

    render() {
      const { width, height } = this.props;
      return (
        <PureCanvas
          width={width}
          height={height}
          canvasRef={this.#canvas}
        />
      );
    }
}

AnimatedCanvas.propTypes = {
  frames: PropTypes.arrayOf(PropTypes.instanceOf(Element)),
  delay: PropTypes.number,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

AnimatedCanvas.defaultProps = {
  frames: [],
  delay: 150,
};

export default AnimatedCanvas;
