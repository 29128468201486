import React from 'react';
import PropTypes from 'prop-types';

import PureCanvas from './PureCanvas';

// after some experimentation, i've found that using a second canvas as the backdrop
// gives smoother performance when rapidly changing the colour than a plain, coloured div
// the overall fps boost isn't much but not having everything hang for garbage collection
// every few seconds is a huge improvement
class Backdrop extends React.Component {
  constructor(props) {
    super(props);
    this.#canvas = React.createRef();
  }

  componentDidMount() {
    this.draw();
  }

  componentDidUpdate(prevProps) {
    // redraw on any props change
    const { width, height, colour } = this.props;
    if (
      width !== prevProps.width
            || height !== prevProps.height
            || colour.r !== prevProps.colour.r
            || colour.g !== prevProps.colour.g
            || colour.b !== prevProps.colour.b
    ) {
      this.draw();
    }
  }

  // TODO: why the fuck does eslint want this here????
    #canvas;

    draw() {
      const context = this.#canvas.current.getContext('2d', { alpha: false });

      const { width, height, colour } = this.props;
      context.fillStyle = `rgb(${colour.r}, ${colour.g}, ${colour.b})`;
      context.fillRect(0, 0, width, height);
    }

    render() {
      const { width, height } = this.props;
      return (
        <PureCanvas
          width={width}
          height={height}
          canvasRef={this.#canvas}
        />
      );
    }
}

Backdrop.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  colour: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
  }),
};

Backdrop.defaultProps = {
  colour: {
    r: 0,
    g: 0,
    b: 0,
  },
};

export default Backdrop;
