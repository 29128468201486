import React from 'react';
import styled from 'styled-components';

import { CornerContainer, ProseContainer } from '../components/Container';
import { Header, Subheader, Subsubheader } from '../components/Headers';

const AppContainerInner = styled.div`
    display: inline-flex;
    flex-direction: column;
    margin: auto;
    color: white;
    width: 100%;
    max-width: 800px;
    text-align: center;
    padding-bottom: 10px;

    & > * {
        margin-bottom: -1px;
    }

    & > :last-child {
        margin-bottom: 0;
    }

    @media only screen and (max-width: 500px) {
        width: 100%;
    }
`;
//
// const HorizontalContainer = styled.div`
//    display: inline-flex;
//
//    & > * {
//        margin-right: -1px;
//        flex: 1;
//    }
//
//    & > :last-child {
//        margin-right: 0;
//    }
// `;

const ListContainer = styled.div`
    text-align: left;
    margin-top: 15px;
`;

const PuddleList = styled.ul`
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    column-gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1.4em;

    & > li {
        line-height: 1.375;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

// these should really be rolled components/Container
// hell, left align should probably be default
const HeaderContainer = styled(CornerContainer)`
    justify-content: center;
`;

const About = () => (
  <AppContainerInner>
    <HeaderContainer><Header>About</Header></HeaderContainer>

    <ProseContainer>
      <Subheader>What I Do</Subheader>
      <p>
        PolyCement is a web developer situated in the north of England. She specialises in frontend
        development with React but often works full-stack, and has experience in a wide range of web
        technologies and frameworks across a number of different languages. She aims to create
        websites with a unique look and feel without sacrificing UX in the process, and enjoys
        treading new ground online, pushing web technologies to their limits. Aside from web
        development, she also has experience in building multi-platform desktop applications and
        video games, and is well-versed in a number of other programming languages.
      </p>

      <p>
        In her spare time she studies Japanese, plays video games and programs various toy
        applications, some of which can be seen on this site. She is an avid Linux user, and has
        been running it as her daily driver for over a decade. Her other hobbies include sewing,
        game development, collecting audio samples and, on rare occasion, actually producing music
        with those samples.
      </p>
    </ProseContainer>

    <CornerContainer>
      <Subheader>What I Know</Subheader>
      <ListContainer>
        <Subsubheader>JavaScript</Subsubheader>
        <PuddleList>
          <li>React</li>
          <li>jQuery</li>
          <li>Mithril</li>
          <li>Bootstrap</li>
          <li>tone.js</li>
          <li>RxJS</li>
          <li>node.js</li>
          <li>Express</li>
          <li>Gatsby</li>
          <li>Next</li>
          <li>Electron</li>
          <li>Wails</li>
          <li>TypeScript</li>
          <li>Elm</li>
        </PuddleList>
        <Subsubheader>Databases</Subsubheader>
        <PuddleList>
          <li>MongoDB</li>
          <li>MySQL</li>
          <li>PostgreSQL</li>
        </PuddleList>
        <Subsubheader>APIs</Subsubheader>
        <PuddleList>
          <li>Twitch</li>
          <li>Streamlabs</li>
          <li>YouTube Player</li>
          <li>YouTube Data</li>
          <li>Web Audio</li>
          <li>Discord</li>
          <li>Twitter</li>
          <li>PayPal</li>
          <li>GoCardless</li>
          <li>NationBuilder</li>
          <li>Action Network</li>
          <li>Airtable</li>
        </PuddleList>
        <Subsubheader>Other Technologies & Frameworks</Subsubheader>
        <PuddleList>
          <li>Django</li>
          <li>Phoenix</li>
          <li>Wordpress</li>
          <li>Docker</li>
        </PuddleList>
        <Subsubheader>Other Languages</Subsubheader>
        <PuddleList>
          <li>Java</li>
          <li>C</li>
          <li>C++</li>
          <li>C#</li>
          <li>D</li>
          <li>Python</li>
          <li>Bash</li>
          <li>Lua</li>
          <li>Go</li>
          <li>Elixir</li>
        </PuddleList>
        <Subsubheader>Game Engines & Frameworks</Subsubheader>
        <PuddleList>
          <li>Unity</li>
          <li>Godot</li>
          <li>löve</li>
          <li>Twine</li>
        </PuddleList>
      </ListContainer>
    </CornerContainer>
  </AppContainerInner>
);

export default About;
