// NOTE: this worker has a specific rule in the webpack config cos gif.js needs a url
import GifWorker from '../vendor/gif.js/gif.worker';
import Gif from '../vendor/gif.js/gif';

import { replaceColours } from './canvasUtils';

const generateGif = (frames, colour, delay, callback) => {
  // first generate the full size frames
  const colours = [
    {
      r: 240, g: 0, b: 0, a: 255,
    },
    {
      r: 192, g: 0, b: 0, a: 255,
    },
  ];
  const targetColours = [
    {
      r: colour.r, g: colour.g, b: colour.b, a: 255,
    },
    {
      r: colour.r * 0.605, g: colour.g * 0.605, b: colour.b * 0.605, a: 255,
    },
  ];
  const recolouredFrames = [];
  frames.forEach((f) => {
    recolouredFrames.push(replaceColours(f, colours, targetColours));
  });
  console.log(recolouredFrames[0].getContext('2d').getImageData(0, 0, frames[0].width, frames[0].height));

  // swap transparent colour if the main colour is #00ff00
  let backgroundColour;
  let transparentColour;
  if (colour.r === 0 && colour.g === 255 && colour.b === 0) {
    backgroundColour = '#00f';
    transparentColour = 0x0000ff;
  } else {
    backgroundColour = '#0f0';
    transparentColour = 0x00ff00;
  }

  const gif = new Gif({
    width: frames[0].width,
    height: frames[0].height,
    workers: 4,
    workerScript: GifWorker,
    background: backgroundColour,
    // NOTE: this HAS to be hex or it doesn't fucking work.
    // i think it's to do with the findClosest function of GIFEncoder
    // it shoves the rgb value thru a bunch of bitshifts without checking it's a hex value first
    // maybe i'll submit a pull request some time... or at least raise an issue
    transparent: transparentColour,
  });

  recolouredFrames.forEach((f) => {
    gif.addFrame(f, { delay });
  });

  gif.on('finished', (blob) => {
    callback(blob);
  });

  gif.render();
};

export default generateGif;
