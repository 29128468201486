import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AnimatedCanvas from './AnimatedCanvas';
import Backdrop from './Backdrop';
import { CornerContainer } from '../components/Container';

const DanceFloorContainer = styled(CornerContainer)`
    flex: 0 0 auto;

    @media only screen and (min-width: 800px) {
        width: 700px;
    }
`;

const DanceFloorWhitespace = styled.div`
    position: relative;
    background: white;
    width: 700px;
    padding-bottom: 57.14%;

    @media only screen and (max-width: 800px) {
        width: 100%;
    }
`;

class DanceFloor extends React.Component {
  constructor(props) {
    super(props);
    if (props.frames && props.frames.length > 0) {
      this.state = {
        width: props.frames[0].width,
        height: props.frames[0].height,
      };
    } else {
      this.state = {
        width: 0,
        height: 0,
      };
    }
  }

  componentDidUpdate(prevProps) {
    const { frames } = this.props;
    if (
      frames && frames.length > 0 && frames !== prevProps.frames
    ) {
      // react docs literally do this fuck off
      // TODO: maybe theres a workaround look into that
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        width: frames[0].width,
        height: frames[0].height,
      });
    }
  }

  render() {
    const { colour, frames, delay } = this.props;
    const { width, height } = this.state;
    return (
      <DanceFloorContainer>
        <div>
          <DanceFloorWhitespace>
            <>
              <Backdrop
                colour={colour}
                width={width}
                height={height}
              />
              <AnimatedCanvas
                frames={frames}
                delay={delay}
                width={width}
                height={height}
              />
            </>
          </DanceFloorWhitespace>
        </div>
      </DanceFloorContainer>
    );
  }
}

DanceFloor.propTypes = {
  // TODO: might be able to be more specific than element...
  frames: PropTypes.arrayOf(PropTypes.instanceOf(Element)),
  colour: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
  }),
  delay: PropTypes.number,
};

DanceFloor.defaultProps = {
  frames: [],
  colour: {
    r: 0,
    g: 0,
    b: 0,
  },
  delay: 150,
};

export default DanceFloor;
