import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../components/Button';
import { CornerContainer } from '../components/Container';

const FileSelectorHeader = styled.div`
    text-align: center;
    margin-bottom: 10px;
`;

const HiddenFileInput = styled.input.attrs({
  type: 'file',
  accept: 'audio/*',
})`
    display: none;
`;

const FakeFileInput = styled(Button)`
    min-width: 100%;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const FileSelector = ({ onChange }) => {
  const hiddenInputRef = React.useRef();
  const [filename, setFilename] = React.useState('Browse...');

  return (
    <CornerContainer>
      <HiddenFileInput
        onChange={(e) => {
          setFilename(e.target.files[0].name);
          onChange(e);
        }}
        ref={hiddenInputRef}
      />
      <FileSelectorHeader>
        Choose an audio file to convert into &quot;HD&nbsp;SOUND&quot;.
      </FileSelectorHeader>
      <FakeFileInput
        onClick={() => hiddenInputRef.current.click()}
      >
        {filename}
      </FakeFileInput>
    </CornerContainer>
  );
};

FileSelector.propTypes = {
  onChange: PropTypes.func,
};

FileSelector.defaultProps = {
  onChange: undefined,
};

export default FileSelector;
