import React from 'react';
import { useLocation } from 'react-router-dom';

import DialogButton from './components/DialogButton';

import DanceHelpDialog from './dance/HelpDialog';
import KanaHelpDialog from './kana/HelpDialog';
import SoundHelpDialog from './hdsound/HelpDialog';

const HelpMap = {
  '/kana': KanaHelpDialog,
  '/sound': SoundHelpDialog,
  '/dance': DanceHelpDialog,
};

const HelpButton = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const location = useLocation();

  if (!(location.pathname in HelpMap)) return null;

  const TheDialog = HelpMap[location.pathname];

  if (isOpen) {
    return (
      <TheDialog
        onClick={() => {
          setIsOpen(false);
        }}
      />
    );
  }
  return (
    <DialogButton
      text="Help"
      onClick={() => {
        setIsOpen(true);
      }}
    />
  );
};

export default HelpButton;
