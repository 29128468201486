import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { ResizeObserver } from '@juggle/resize-observer';

import LinkList from './LinkList';
import { CornerContainer } from './components/Container';
import Overlay from './components/Overlay';
import VerticalButton from './components/VerticalButton';

// NOTE: i did attempt to replace this with linear gradient tricks but the results were. not great.
// i'll leave it for now...
import sidebarBottom from './img/sidebarBottom.svg';

// background divs, used for drawing the lines in the right places

const SidebarTopRight = styled.div`
    grid-area: 1 / 2 / 4 / 2;
    border-right: thin solid yellow;
`;

const SidebarBg = styled.div`
    display: grid;
    grid-area: 1 / 1 / 5 / 1;
    border-right: thin solid yellow;
`;

const SidebarBgBottom = styled.div`
    display: grid;
    grid-area: 5 / 1 / 5 / 3;
    background-image: url(${sidebarBottom});
    background-position: top right;
    background-repeat: no-repeat;
`;

// here's the actual functional stuff
// TODO: this but better
const menuBodyWidth = 200;

const pullOut = (props) => keyframes`
    0% {
        transform: translate(${-menuBodyWidth}px, ${props.hiddenYOffset}px);
    }
    50% {
        transform: translate(${-menuBodyWidth}px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
`;

const pullIn = (props) => keyframes`
    0% {
        transform: translate(${menuBodyWidth}px, ${-props.hiddenYOffset}px);
    }
    50% {
        transform: translate(0, ${-props.hiddenYOffset}px);
    }
    100% {
        transform: translate(0, 0);
    }
`;

// this is a little goofy so i'll explain:
// when the menu is hidden, it's positioned using top/left
// when the button is pressed, the positioning is set to 0/0, but the animation immediately
// applys a transform back to the hidden position, then animates into the shown position
// the same is true of retracting the menu
// this is to ensure clean rendering in chrome by not keeping the menu in a transformed state,
// while still taking advantage of transforms for smooth and efficient animation
const SidebarContainer = styled.div`
    display: grid;
    grid-template-columns: ${menuBodyWidth}px 20px;
    grid-template-rows: auto auto auto auto 40px;
    position: fixed;
    overflow-y: scroll;
    ${(props) => props.visible && 'max-height: 100%'};
    top: ${(props) => (props.visible ? '0' : `${Math.floor(props.hiddenYOffset)}px`)};
    left: ${(props) => (props.visible ? '0' : `-${Math.floor(menuBodyWidth)}px`)};
    width: ${menuBodyWidth + 40}px;
    color: white;
    visibility: ${(props) => (props.hiddenYOffset === 0 ? 'hidden' : 'visible')};
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
`;

const SidebarContainerOut = styled(SidebarContainer)`
    animation: ${(props) => pullOut(props)} 0.5s;
`;

const SidebarContainerIn = styled(SidebarContainer)`
    animation: ${(props) => pullIn(props)} 0.5s;
`;

const SidebarHeader = styled(CornerContainer)`
    grid-area: 2 / 1 / 2 / 1;
    font-size: 24px;
    color: yellow;
    font-family: 'Squada One', sans-serif;
    text-align: center;
    width: 162px;
    padding: 10px 0;
    margin: 20px auto;
`;

const MenuButton = styled(VerticalButton)`
    grid-area: 4 / 2 / 4 / 2;
    transform: translateX(10px);
`;

const overlayFadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const overlayFadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const StyledOverlay = styled(Overlay)`
    position: fixed;
    z-index: 0;
    opacity: ${(props) => (props.state === 'show' ? 1 : 0)};
    animation: ${(props) => {
    if (props.state === 'fadeIn' || props.state === 'show') {
      return css`${overlayFadeIn} .25s ease .25s`;
    }
    return css`${overlayFadeOut} .25s ease`;
  }
};
`;

const SidebarOverlay = ({ onClick, visible }) => {
  const [visibility, setVisibility] = React.useState('hide');

  // i cant think of a simpler way to do this than handling it like a state machine...
  React.useEffect(() => {
    if (visible && (visibility === 'hide' || visibility === 'fadeOut')) {
      setVisibility('fadeIn');
    } else if (!visible && (visibility === 'show' || visibility === 'fadeIn')) {
      setVisibility('fadeOut');
    }
  }, [visible]);

  return (
    visibility !== 'hide'
      ? (
        <StyledOverlay
          state={visibility}
          onClick={onClick}
          onAnimationEnd={() => {
            setVisibility(visible ? 'show' : 'hide');
          }}
        />
      )
      : null);
};

SidebarOverlay.propTypes = {
  onClick: PropTypes.func,
  visible: PropTypes.bool,
};

SidebarOverlay.defaultProps = {
  onClick: null,
  visible: true,
};

// TODO: state has manifested here... should this be a class component?
const Sidebar = ({ visible, onClick }) => {
  const [sidebarHeight, setSidebarHeight] = React.useState(0);
  const [menuButtonHeight, setMenuButtonHeight] = React.useState(0);
  const [SidebarType, setSidebarType] = React.useState(SidebarContainer);
  const sidebarRef = React.useRef();
  const menuButtonRef = React.useRef();

  // track height of sidebar and menu button using resizeobserver
  // so font/font size changes dont screw up the offset when hidden
  // i could just do this using calc and em but it'd be wayyyy more complicated and hard to maintain
  React.useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        // TODO: investigate why borderBoxSize is unavailable on some browsers
        // i suspect the polyfill is attempting to fall back to existing implementations
        // which may be outdated, and hence be missing borderBoxSize...
        if (entry.target === sidebarRef.current) {
          if (entry.borderBoxSize) {
            setSidebarHeight(entry.borderBoxSize[0].blockSize);
          } else {
            setSidebarHeight(entry.contentRect.height);
          }
        } else if (entry.target === menuButtonRef.current) {
          if (entry.borderBoxSize) {
            setMenuButtonHeight(entry.borderBoxSize[0].blockSize);
          } else {
            // NOTE: this'll need adjusting if i change the padding on the menu button
            setMenuButtonHeight(entry.contentRect.height + 12);
          }
        }
      });
    });

    ro.observe(sidebarRef.current);
    ro.observe(menuButtonRef.current);
  }, []);

  // TODO: ???? what is this ????
  React.useEffect(() => {
  }, [visible]);

  return (
    <>
      <SidebarOverlay
        onClick={() => {
          setSidebarType(visible ? SidebarContainerIn : SidebarContainerOut);
          onClick();
        }}
        visible={visible}
      />
      <SidebarType
        visible={visible}
        hiddenYOffset={Math.min(0, (menuButtonHeight + 60) - sidebarHeight)}
        ref={sidebarRef}
      >
        <SidebarBg />
        <SidebarBgBottom />
        <SidebarTopRight />
        <SidebarHeader><span>Cool Goth Zone</span></SidebarHeader>
        <LinkList
          dummy={!visible}
          onClick={() => {
            setSidebarType(visible ? SidebarContainerIn : SidebarContainerOut);
            onClick();
          }}
        />
        <MenuButton
          onClick={() => {
            setSidebarType(visible ? SidebarContainerIn : SidebarContainerOut);
            onClick();
          }}
          text="Menu"
          ref={menuButtonRef}
        />
      </SidebarType>
    </>
  );
};

Sidebar.propTypes = {
  visible: PropTypes.bool,
  onClick: PropTypes.func,
};

Sidebar.defaultProps = {
  visible: false,
  onClick: null,
};

export default Sidebar;
