// script for loading letter spritesheets and compiling them into an animation

// import all the dancing letter gifs
const reqCache = {};
const importAll = (req) => req.keys().forEach((key) => { reqCache[key.replace('./', '')] = req(key); });
importAll(require.context('../img/letters/', false, /\.gif$/i));

// hardcoded image info
const frameHeight = 190;
const numFrames = 14;

// this will be read in from the page eventually
const overlap = 40;

// ============= IMAGE LOADING =============

const charImages = {};

const specialChars = {
  ' ': 'space',
  '!': 'exclamationmark',
  '?': 'questionmark',
  '@': 'atsymbol',
  '&': 'ampersand',
  $: 'dollarsign',
  '.': 'fullstop',
};

// TODO: maybe drag the last line of loadImage up here? simpler code?
// return a character's corresponding image path
const charToPath = (c) => {
  let char = c;
  console.log(`loading char ${c}`);
  if (char in specialChars) {
    char = specialChars[char];
  } else if (!(/[0-9a-z]/.test(char))) {
    console.log('aaa');
    throw new Error(`Character ${char} is not supported!`);
  }
  return reqCache[`${char}.gif`].default;
};

// TODO: handle that error
// set an image to load and return a promise which resolves when loading completes
const loadImage = (imgPath) => new Promise((resolve, reject) => {
  const charImg = new Image();
  charImg.addEventListener('load', () => {
    resolve(charImg);
  });
  charImg.addEventListener('error', () => {
    // the callback arg is just charImg so we don't bother with it
    reject(new Error(`Failed to load image ${charImg.src}!`));
  });
  charImg.src = imgPath;
});

// ensure all images needed to draw the given string are loaded
// string should be cleaned up before it reaches this function so don't worry about validation
const loadImagesForString = async (s) => {
  // reduce the string to a set of unique chars
  const uniqueChars = new Set();
  s.split('').forEach((c) => {
    uniqueChars.add(c);
  });
  // now check which chars aren't loaded
  const unloadedChars = [];
  uniqueChars.forEach((c) => {
    if (!(c in charImages)) {
      unloadedChars.push(c);
    }
  });
  // load anything that's unloaded then return
  await Promise.all(unloadedChars.map(async (c) => {
    const charPath = charToPath(c);
    charImages[c] = await loadImage(charPath);
  }));
};

// ============= ANIMATION RENDERING =============

// calculates the width of the given (single line) string
const calcLineWidth = (s) => {
  if (s.length === 0) return 0;
  let width = 0;
  s.split('').forEach((c) => {
    width += charImages[c].width;
  });
  return width - overlap * (s.length - 1);
};

// render a frame of the animation to a canvas, and return the canvas
const renderFrame = (s, currentFrameIdx) => {
  // create a new canvas
  // TODO: can i just reuse the same one? hmm
  // also i don't know what happens to this when i'm done with it.
  // should definitely make sure it's actually freed
  const frameCanvas = document.createElement('canvas');
  const frameContext = frameCanvas.getContext('2d');
  // make the canvas the exact right size
  const lines = s.split('\n');
  let maxLineWidth = 0;
  lines.forEach((l) => {
    maxLineWidth = Math.max(maxLineWidth, calcLineWidth(l));
  });
  frameCanvas.width = maxLineWidth;
  // TODO: stop using overlap here!
  frameCanvas.height = frameHeight * lines.length + overlap * (lines.length - 1);
  // draw the frame
  let currentYPos = 0;
  for (let lineIdx = 0; lineIdx < lines.length; lineIdx += 1) {
    const currentLine = lines[lineIdx];
    let currentXPos = (maxLineWidth - calcLineWidth(currentLine)) / 2;
    for (let charIdx = 0; charIdx < currentLine.length; charIdx += 1) {
      const currentChar = currentLine.charAt(charIdx);
      const img = charImages[currentChar];
      frameContext.drawImage(img, 0, frameHeight * currentFrameIdx, img.width, frameHeight,
        Math.floor(currentXPos), Math.floor(currentYPos),
        img.width, frameHeight);
      currentXPos += img.width - overlap;
    }
    currentYPos += frameHeight + overlap;
  }
  return frameCanvas;
};

// renders the animation, assumes all images are already loaded
const renderAnimation = (message) => {
  // render the frames
  const frames = [];
  for (let currentFrameIdx = 0; currentFrameIdx < numFrames; currentFrameIdx += 1) {
    frames.push(renderFrame(message, currentFrameIdx));
  }
  return frames;
};

// takes a message, returns a set of frames to be animated by a canvas
const generate = async (message) => {
  // TODO: return something useful if loading images fails
  try {
    console.log(message);
    await loadImagesForString(message.replace(/\n/g, ''));
  } catch (err) {
    console.log(err);
    return [];
  }
  return renderAnimation(message);
};

export default generate;
