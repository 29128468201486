import React from 'react';

import Section from './Section';

import SubMergePic from '../img/submerge.png';
import CostumeCatastrophePic from '../img/costumecatastrophe.png';
import BloodAndLossPic from '../img/bloodandloss.png';
import BuildADildPic from '../img/buildadild.png';

const GamesTopic = () => (
  <>
    <Section
      title="Sub/Merge"
      img={SubMergePic}
      link={{
        name: 'Download or Play Online (itch.io)',
        location: 'https://polycement.itch.io/submerge',
      }}
    >
      A containment breach in a secret research facility traps a janitor alone with a horde of
      slimes. The player controls this protagonist as they attempt to escape the facility by
      climbing into slimes with varying elemental properties and moving them around to solve
      puzzles, all while trying to avoid being transformed into a slime themselves. Created for
      Strawberry Jam 2019. Made in Godot.
    </Section>
    <Section
      title="Blood & Loss"
      img={BloodAndLossPic}
      link={{
        name: 'Download or Play Online (itch.io)',
        location: 'https://polycement.itch.io/blood-and-loss',
      }}
    >
      A top-down action-adventure game in the vein of 2D Zelda titles. The player controls a
      blood mage as she attempts to reach the top of a tower filled with monsters, but must plan
      their engagements carefully as their only means of attack are powerful blood arts which
      quickly drain the protagonist&apos;s health. Created for Ludum Dare 43. Made in Godot.
    </Section>
    <Section
      title="Costume Catastrophe"
      img={CostumeCatastrophePic}
      link={{
        name: 'Download or Play Online (itch.io)',
        location: 'https://polycement.itch.io/costume-catastrophe',
      }}
    >
      A dress-up game with a novel control scheme based on those more commonly associated with
      golfing titles. Costume parts appear on shelves of varying heights, and the player must
      control the height and accuracy of the protagonist&apos;s jump to collect matching parts for
      each costume. Created for GDQ One Mechanic Game Jam 5. Made in Godot.
    </Section>
    <Section
      title="Build-A-Dild"
      img={BuildADildPic}
      link={{
        name: 'Download (itch.io) (18+ Only, NSFW Content)',
        location: 'https://polycement.itch.io/build-a-dild',
      }}
    >
      A game about the creation of &quot;High-Grade Silicone Statues&quot;. Gameplay takes the
      form of a series of customers placing orders, which must be fulfilled by selecting parts
      to assemble the finished product. Created for Strawberry Jam 2018. Written in lua using the
      löve2d framework.
    </Section>
  </>
);

export default GamesTopic;
