import React from 'react';
import styled from 'styled-components';

import generateFrames from './generateFrames';
import generateGif from './generateGif';
import {
  replaceColours,
  smallFrame,
} from './canvasUtils';

import ControlPanel from './ControlPanel';
import DanceFloor from './DanceFloor';
import GifOverlay from './GifOverlay';
import Button from '../components/Button';
import { CornerContainer } from '../components/Container';
import { Header, Subheader } from '../components/Headers';

const INITIAL_MESSAGE = 'Hello\nWorld!';
const MAX_FRAME_WIDTH = 640;
const MAX_FRAME_HEIGHT = 360;

const GifButton = styled(Button)`
    width: 100%;
`;

// what i'm doing here might seem a little nasty
// but it's a hell of a lot simpler than making each individual segment
// able to fade its contents, and also cleaner
const AppContainerInner = styled.div`
    display: inline-flex;
    flex-direction: column;
    margin: auto;
    padding-bottom: 10px;

    & > * {
        margin-bottom: -1px;
    }

    & > :last-child {
        margin-bottom: 0;
    }

    ${(props) => props.loading && `
        & ${CornerContainer} {
            cursor: not-allowed;
        }

        & ${CornerContainer} > div > * {
            opacity: 30%;
            pointer-events: none;
        }
    `}

    @media only screen and (max-width: 800px) {
        width: 100%;
    }
`;

const UnfadingHeader = styled(Header)`
    opacity: 100% !important;
`;

// doin some nasty shit to make this the same size as the dancefloor
const LoadingContainer = styled(CornerContainer)`
    position: relative;
    flex: 0 0 auto;
    width: 700px;
    padding-bottom: calc(57.14% - 2px);

    @media only screen and (max-width: 800px) {
        width: 100%;
    }
`;

const LoadingInner = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    opacity: 100% !important;
`;

// removes anything we can't process from the string
const sanitise = (s) => s.toLowerCase().replace(/[^0-9a-z @!?$.&\n]/g, '');

// i'll small your frames
const generateSmalledFrames = (frames, maxWidth, maxHeight) => {
  const newSmalledFrames = [];
  frames.forEach((f) => {
    newSmalledFrames.push(smallFrame(f, maxWidth, maxHeight));
  });

  return newSmalledFrames;
};

const generateTransparentFrames = (frames) => {
  // make transparency white, bright red transparent, dark red 39.5% transparent black
  const colours = [
    {
      r: 0, g: 0, b: 0, a: 0,
    },
    {
      r: 240, g: 0, b: 0, a: 255,
    },
    {
      r: 192, g: 0, b: 0, a: 255,
    },
  ];
  const targetColours = [
    {
      r: 255, g: 255, b: 255, a: 255,
    },
    {
      r: 0, g: 0, b: 0, a: 0,
    },
    {
      r: 0, g: 0, b: 0, a: 101,
    },
  ];

  return frames.map((f) => replaceColours(f, colours, targetColours));
};

class DanceApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // stuff from the control panel
      message: sanitise(INITIAL_MESSAGE),
      delay: 150,
      colour: { r: 240, g: 0, b: 0 },

      // internal stuff
      frames: [],
      smalledFrames: [],
      gif: undefined,
      filename: undefined,
      showGif: undefined,
      gifFresh: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.updateFrames();
  }

  onClickSetMessage(value) {
    // sanitise string and shove it in the state
    // TODO: better fix for empty string? validation maybe?
    let newMessage = sanitise(value);
    if (newMessage === '') {
      newMessage = ' ';
    }
    const { message } = this.state;
    if (newMessage !== message) {
      this.setState({
        message: newMessage,
        gifFresh: false,
        loading: true,
      });
      // theres currently no way to guarantee a paint has happened before executing code
      // which means updateFrames can start before the loading screen comes up
      // so for now we pause for a moment to make sure the ui can update first
      setTimeout(() => this.updateFrames(), 50);
    }
  }

  onColourChange(newColour) {
    this.setState({
      colour: newColour,
      gifFresh: false,
    });
  }

  onDelayChange(newDelay) {
    this.setState({
      delay: newDelay,
      gifFresh: false,
    });
  }

  onClickGenerate() {
    const { gifFresh } = this.state;
    if (gifFresh) {
      this.setState({ showGif: true });
    } else {
      const {
        frames, colour, delay, message,
      } = this.state;
      this.setState({ loading: true });
      setTimeout(() => {
        generateGif(frames, colour, delay, (blob) => {
          // sadly there's no way to give the image a suggested filename
          // a workaround would be to set the image so clicking on it opens the save dialog
          // a name can actually be set that way, for some reason
          this.setState({
            gif: URL.createObjectURL(blob),
            filename: `${message.replace(/\s+/g, '_').replace(/\W/g, '')}.gif`,
            showGif: true,
            gifFresh: true,
            loading: false,
          });
        });
      }, 50);
    }
  }

  async updateFrames() {
    // generate full-size frames
    const { message } = this.state;
    const baseFrames = await generateFrames(message);
    if (!baseFrames) {
      this.setState({ loading: false });
      return;
    }
    // and resized colour mask versions for display
    const newFrames = generateTransparentFrames(baseFrames);
    const newSmalledFrames = generateSmalledFrames(newFrames, MAX_FRAME_WIDTH, MAX_FRAME_HEIGHT);

    this.setState({
      frames: baseFrames,
      smalledFrames: newSmalledFrames,
      loading: false,
    });
  }

  render() {
    const {
      loading, gif, filename, showGif, colour, delay, gifFresh, smalledFrames,
    } = this.state;
    return (
      <>
        <GifOverlay
          image={gif}
          filename={filename}
          visible={showGif}
          onOverlayClick={() => this.setState({ showGif: false })}
        />
        <AppContainerInner loading={loading}>
          <CornerContainer>
            <UnfadingHeader>Dance</UnfadingHeader>
          </CornerContainer>
          <ControlPanel
            message={INITIAL_MESSAGE}
            onClickSetMessage={(value) => this.onClickSetMessage(value)}
            colour={colour}
            onColourChange={(newColour) => this.onColourChange(newColour)}
            delay={delay}
            onDelayChange={(newDelay) => this.onDelayChange(newDelay)}
          />
          { loading ? (
            <LoadingContainer>
              <div>
                <LoadingInner>
                  <Subheader>Generating...</Subheader>
                  <p>The page may appear to freeze. This is normal!</p>
                </LoadingInner>
              </div>
            </LoadingContainer>
          ) : (
            <DanceFloor
              colour={colour}
              frames={smalledFrames}
              delay={delay}
            />
          )}
          <CornerContainer>
            <GifButton onClick={() => this.onClickGenerate()}>
              { gifFresh ? 'Show GIF' : 'Create GIF'}
            </GifButton>
          </CornerContainer>
        </AppContainerInner>
      </>
    );
  }
}

export default DanceApp;
