import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../components/Button';
import { CornerContainer } from '../components/Container';

import formatTime from '../util';

const ToolbarContainer = styled(CornerContainer)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Toolbar = ({
  disabled, time, onRestartClick, onResetClick,
}) => (
  <ToolbarContainer>
    <Button
      onClick={() => onRestartClick()}
      disabled={disabled}
    >
      Restart
    </Button>
    { formatTime(time) }
    <Button
      onClick={() => onResetClick()}
      disabled={disabled}
    >
      Mode
    </Button>
  </ToolbarContainer>
);

Toolbar.propTypes = {
  disabled: PropTypes.bool,
  time: PropTypes.number,
  onRestartClick: PropTypes.func,
  onResetClick: PropTypes.func,
};

Toolbar.defaultProps = {
  disabled: false,
  time: 0,
  onRestartClick: null,
  onResetClick: null,
};

export default Toolbar;
