import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '../components/Dialog';

const HelpDialog = ({ onClick }) => (
  <Dialog
    title="Welcome to Kana Zone"
    buttonText="Return"
    onClick={onClick}
    onOverlayClick={onClick}
    visible
  >
    <p>Click on a column to disable/enable the characters in it.</p>
    <p>Click &quot;Hiragana&quot; or &quot;Katakana&quot; to begin with that kana set.</p>
    <p>
      Once the game has begun, click and drag each kana to the
      box next to its corresponding romaji.
    </p>
    <p>がんばって！</p>
  </Dialog>
);

HelpDialog.propTypes = {
  onClick: PropTypes.func,
};

HelpDialog.defaultProps = {
  onClick: null,
};

export default HelpDialog;
