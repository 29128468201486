import styled from 'styled-components';

import { buttonStyle } from './Button';

// wanted to have a "Link" component but react-router would make it kind of a mess
// so here's my solution i guess?

// regular hyperlink for use in text content
// TODO: :visited doesn't seem to actually work,
const InlineLink = styled.a`
    &:link {
        color: yellow;
    }

    &:visited {
        color: #FFFF00CC;
    }

    &:hover, &:active {
        color: white;
    }
`;

// max width link. it's just a regular hyperlink
const WideLink = styled.a`
    ${buttonStyle}
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
    text-transform: none;

    &:hover, &:active {
        color: black;
        background: yellow;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

export {
  InlineLink,
  WideLink,
};
